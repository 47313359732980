import { useTimeDifference } from 'hooks/useTimeDifference';
import { useAppStore } from 'store';

export function Timer() {
  const [ currentRound, isLoading ] = useAppStore(s => [s.currentRound, s.isLoading]);

  const { days, hours, minutes, seconds } = useTimeDifference(
    currentRound ? new Date(currentRound?.finishAt).getTime() : 0,
    !isLoading
  );

  return (
    <div className='flex items-center gap-[.25em]'>
      <div className='flex justify-center items-center w-[2.667em] h-[2.667em] rounded-xl font-bold leading-none text-[2.25em]'>
        <div>
          {days}
          <span className='ml-1 font-normal 0.8333 leading-5 pt-[0.53125em] pb-[0.1563em] text-[#fff]'>
            d
          </span>
        </div>
      </div>
      <div className='flex justify-center items-center w-[2.667em] h-[2.667em] rounded-xl font-bold leading-none text-[2.25em]'>
        <div>
          {hours}
          <span className='ml-1 font-normal 0.8333 leading-5 pt-[0.53125em] pb-[0.1563em] text-[#fff]'>
            h
          </span>
        </div>
      </div>
      <div className='flex justify-center items-center w-[2.667em] h-[2.667em] rounded-xl font-bold leading-none text-[2.25em]'>
        <div>
          {minutes}
          <span className='ml-1 font-normal 0.8333 leading-5 pt-[0.53125em] pb-[0.1563em] text-[#fff]'>
            m
          </span>
        </div>
      </div>
      <div className='flex justify-center items-center w-[2.667em] h-[2.667em] rounded-xl font-bold leading-none text-[2.25em]'>
        <div>
          {seconds}
          <span className='ml-1 font-normal 0.8333 leading-5 pt-[0.53125em] pb-[0.1563em] text-[#fff]'>
            s
          </span>
        </div>
      </div>
    </div>
  );
}
