import { useForm } from 'react-hook-form';
import { Button } from 'ui-kit/Button';
import { FormField } from './FormField';
import { FormTextArea } from './FormTextArea';
import { FeedbackFormType } from './scheme';
import { authApi } from 'services/auth';
import { useEffect } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';

export function FeedbackForm() {
  const form = useForm<FeedbackFormType>();
  const { t } = useTranslation()

  const {
    formState: { isSubmitSuccessful },
    reset,
  } = form;

  const handleSubmit = async (data: FeedbackFormType) => {
    try {
      await authApi.sendMessage(
        data.name,
        data.email.toLowerCase(),
        data.message
      );

      form.reset();
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (isSubmitSuccessful) {
      setTimeout(() => {
        reset();
      }, 5000);
    }
  }, [isSubmitSuccessful]);

  return (
    <div className='flex flex-col justify-between text-white '>
      <form
        action=''
        method='POST'
        className='grow flex flex-col gap-2 font-formatDJR-r mt-[2em]'
        onSubmit={form.handleSubmit(handleSubmit)}
      >
        <FormField
          placeholder={t('yourName')}
          required
          {...form.register('name')}
        />
        <FormField
          type='email'
          placeholder={t('yourEmail')}
          required
          {...form.register('email')}
        />

        <div className='relative pb-[4.75em] autofill-fix text-[0.875em] h-[7.625em] pr-[60px] rounded-2xl bg-white bg-opacity-5 outline-none border border-white border-opacity-20 font-[400] resize-none placeholder:text-white'>
          <FormTextArea
            placeholder={t('yourMessage')}
            required
            {...form.register('message')}
          />

          <Button
            disabled={form.formState.isSubmitting}
            type='submit'
            className='h-[3.857em] w-[3.857em] text-[#272D29] absolute bottom-[1em] right-[1em] bg-[url("./assets/vectors/arrow.svg")] bg-no-repeat bg-[center_center] bg-[length:1.5em_1.5em]'
          />
        </div>
      </form>

      <div className='min-h-[2.5em] pt-4 '>
        <AnimatePresence>
          {form.formState.isSubmitSuccessful && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className='text-center'
            >
              {t('messageSuccessfullySent')}
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
}
