import { ReactNode, useLayoutEffect, useRef } from 'react';
import './styles.css';

interface ProgressBarProps {
  /**
   * Progress in percent
   */
  progress: number;
  /**
   * Tooltip content which placed above the current progress
   */
  children?: ReactNode;
}

export function Chart({ progress, children }: ProgressBarProps) {
  const tooltipRef = useRef<HTMLDivElement>(null);

  const handleWindowResize = () => {
    if (!tooltipRef.current) return;

    const tooltip = tooltipRef.current;
    const tooltipBoundingRect = tooltip.getBoundingClientRect();
    const tooltipWidth = tooltipBoundingRect.width;
    const tooltipLeft = tooltipBoundingRect.left;
    const tooltipRight = tooltipBoundingRect.right;
    const windowWidth = window.innerWidth;

    if (tooltipRight > windowWidth) {
      tooltip.style.transform = `translateX(-${tooltipWidth}px)`;
    }

    if (tooltipLeft < 0) {
      tooltip.style.transform = `translateX(0)`;
    }
  };

  useLayoutEffect(() => {
    handleWindowResize();
    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  const circleStyle = {
    strokeDasharray: `${progress}, 100`,
  };

  return (
    <div className='single-chart'>
      <span className='blur backdrop-blur-[20px]'/>
      <svg viewBox='0 0 36 36' className='circular-chart'>
        <path
          className='circle-bg'
          d='M18 2.0845
            a 15.9155 15.9155 0 0 1 0 31.831
            a 15.9155 15.9155 0 0 1 0 -31.831'
        />
        <path
          className='circle'
          style={circleStyle}
          d='M18 2.0845
            a 15.9155 15.9155 0 0 1 0 31.831
            a 15.9155 15.9155 0 0 1 0 -31.831'
        />
      </svg>
      <span className='percentage'>{children}</span>
    </div>
  );
}
