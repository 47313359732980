import { ReactNode } from 'react'
import i18n from 'i18n'

interface RoadmapData {
  color: string
  colorText: string
  sectionName: string
  accordions: {
    title: string
    content: ReactNode
  }[]
}

export const roadmapMockData = (): RoadmapData[] => [
  {
    color: '#6D48A9',
    colorText: '#fff',
    sectionName: i18n.t('desocRoadmap.stage1'),
    accordions: [
      {
        title: i18n.t('desocRoadmap.exclusiveLaunch'),
        content: (
          <ul className=''>
            <li>{i18n.t('desocRoadmap.exclusiveLaunch.description')}</li>
          </ul>
        )
      },
      {
        title: i18n.t('desocRoadmap.socialMedia'),
        content: (
          <ul className=''>
            <li>{i18n.t('desocRoadmap.socialMedia.description')}</li>
          </ul>
        )
      },
      {
        title: i18n.t('desocRoadmap.tokenCreation'),
        content: (
          <ul className=''>
            <li>{i18n.t('desocRoadmap.tokenCreation.description')}</li>
          </ul>
        )
      },
      {
        title: i18n.t('desocRoadmap.promotional'),
        content: (
          <ul className=''>
            <li>{i18n.t('desocRoadmap.promotional.description')}</li>
          </ul>
        )
      }
    ]
  },
  {
    color: '#B9C8FF',
    colorText: '#fff',
    sectionName: i18n.t('desocRoadmap.stage2'),
    accordions: [
      {
        title: i18n.t('desocRoadmap.tokenMarket'),
        content: (
          <ul className=''>
            <li>{i18n.t('desocRoadmap.tokenMarket.description')}</li>
          </ul>
        )
      },
      {
        title: i18n.t('desocRoadmap.earlyDevelopment'),
        content: (
          <ul className=''>
            <li>{i18n.t('desocRoadmap.earlyDevelopment.description')}</li>
          </ul>
        )
      },
      {
        title: i18n.t('desocRoadmap.tradingPair'),
        content: (
          <ul className=''>
            <li>{i18n.t('desocRoadmap.tradingPair.description')}</li>
          </ul>
        )
      },
      {
        title: i18n.t('desocRoadmap.targetedMarketing'),
        content: (
          <ul className=''>
            <li>{i18n.t('desocRoadmap.targetedMarketing.description')}</li>
          </ul>
        )
      }
    ]
  },
  {
    color: '#F3E5FF',
    colorText: '#9784A8',
    sectionName: i18n.t('desocRoadmap.stage3'),
    accordions: [
      {
        title: i18n.t('desocRoadmap.advancedContracts'),
        content: (
          <ul className=''>
            <li>{i18n.t('desocRoadmap.advancedContracts.description')}</li>
          </ul>
        )
      },
      {
        title: i18n.t('desocRoadmap.cryptocurrencyIndex'),
        content: (
          <ul className=''>
            <li>{i18n.t('desocRoadmap.cryptocurrencyIndex.description')}</li>
          </ul>
        )
      },
      {
        title: i18n.t('desocRoadmap.contractAudit'),
        content: (
          <ul className=''>
            <li>{i18n.t('desocRoadmap.contractAudit.description')}</li>
          </ul>
        )
      }
    ]
  },
  {
    color: '#89BFFF',
    colorText: '#fff',
    sectionName: i18n.t('desocRoadmap.stage4'),
    accordions: [
      {
        title: i18n.t('desocRoadmap.communityCentric'),
        content: (
          <ul className=''>
            <li>{i18n.t('desocRoadmap.communityCentric.description')}</li>
          </ul>
        )
      },
      {
        title: i18n.t('desocRoadmap.grandDebut'),
        content: (
          <ul className=''>
            <li>{i18n.t('desocRoadmap.grandDebut.description')}</li>
          </ul>
        )
      },
      {
        title: i18n.t('desocRoadmap.continualMarketing'),
        content: (
          <ul className=''>
            <li>{i18n.t('desocRoadmap.continualMarketing.description')}</li>
          </ul>
        )
      }
    ]
  }
]
