import {
	Chain,
    Wallet,
    getDefaultConfig,
    getDefaultWallets,
    getWalletConnectConnector,
} from '@rainbow-me/rainbowkit';
import {
    coinbaseWallet,
    metaMaskWallet,
		trustWallet,
		walletConnectWallet,
} from '@rainbow-me/rainbowkit/wallets';
import { http } from 'wagmi'
import { mainnet, sepolia } from '@wagmi/core/chains'
import '@rainbow-me/rainbowkit/styles.css';
import { STAGE } from '../constants/api';

export type WagmiConnectors = {
    name: string;
};

export const networks = {
  development: {
		...sepolia,
	},
  production: {
		...mainnet,
	},
};

export const TARGET_NETWORKS = networks[STAGE];

const projectId = '6672301f141b4507ba62a368217fc4d3';

const chains: readonly [Chain, ...Chain[]] = [TARGET_NETWORKS];

export interface MyWalletOptions {
  projectId: string;
}
export const cryptocom = ({ projectId }: MyWalletOptions): Wallet => ({
    id: 'cryptocom',
    name: 'Crypto.com',
    iconUrl:
      'https://explorer-api.walletconnect.com/v3/logo/lg/7c5ff577-a68d-49c5-02cd-3d83637b0b00?projectId=2f05ae7f1116030fde2d36508f472bfb',
    iconBackground: '#fffff',
    downloadUrls: {
      android: 'https://play.google.com/store/apps/details?id=com.defi.wallet',
      ios: 'https://apps.apple.com/us/app/crypto-com-l-defi-wallet/id1512048310?mt=8',
      qrCode: 'https://crypto.com/defi-wallet',
    },
  mobile: {
    getUri: (uri: string) => uri,
  },
  qrCode: {
    getUri: (uri: string) => uri,
    },
  createConnector: getWalletConnectConnector({ projectId }),
});

export const walletConnectWalletCustom = ({ projectId }: MyWalletOptions): Wallet => ({
  ...walletConnectWallet({
    projectId,
    options: {
			qrModalOptions: {
        explorerExcludedWalletIds: [
          'dd43441a6368ec9046540c46c5fdc58f79926d17ce61a176444568ca7c970dcd',
          'c482dfe368d4f004479977fd88e80dc9e81107f3245d706811581a6dfe69c534',
          'c733d32f3b974c4a96e0cd5a3b6f7e186e2f6379182ac6640fdbab4f9ef489b0',
          '5265dcf66be0553328dbc727414ab329e22f9a480e593bd2e927b279e4ab244d',
          '4b2604c8e0f5022d0fbfbc67685dd5e87426bbfe514eebcce6c5f3638f2e1d81',
          'bfa6967fd05add7bb2b19a442ac37cedb6a6b854483729194f5d7185272c5594',
          '1f711d32b1df0f84741fafa2ad1d81599b01297cc7d22d153272cb3ef4232f19',
          '959c4774921adfcd49b30c88eb53f3831df6cc8c2f65577fbdd65c26a342577e',
          '184f15ea2cd5f65e25ed72efdd2ed1979179eddf0c0741ab0dd23c1eb7e0eee5',
          '87eecbca66faef32f044fc7c66090fc668efb02e2d17dda7bf095d51dff76659',
          '3fecad5e2f0a30aba97edea69ebf015884a9b8a9aec93e66d4b4b695fee1f010',
          '4d0cf1b635a59175b4ad6d6522b0b748ee920b1f8c32030fa704c00926efdf3e',
          'f593f4eb9755ff047681a37ebc46706e0e915cf1c2fe0102f5ae47c9f6aa4082',
          '71a83ffbaa7fa38419e8f548a6499c78c8d429163631cf483483fc7efc6b7aae',
          'bcaec16e531fb5f6dc690d7b70d570421e0209af9a0fe77c6419d516fe0098c2',
          'fdcaaa47c154988ff2ce28d39248eb10366ec60c7de725f73b0d33b5bb9b9a64',
          '4c70c9bd85cc4707811ec0912d692855370d465d46188be42530eaeb4a4f3cae',
          '550b59942eb58a7226381bf7935f22d311e56ee29c3530e44d96b1de0550a35a',
          'd166c283d59164538cdc50e414546a7433d5f62b9410c9aa563e4e2ec496a106',
          '6af02afbc4ac21d339fb4290d048d48f9f73c3b1a307a994f0474329948c0e5a',
          '2ba89f94faff121a7c1091c3cea124167dc4291ebe87123620c66e0f120197cc',
          '47bb562043e570200267c79b256c5fc4e005bde1d5628db9205f072243329fbf',
          'dd1112ffb1ba02247ac7d22d69a0249c5ec867918c614ce9256c571ba7636882',
          '6544c9ff3ea25bc10a86b6e213c0f0407b04fb335490d7d56f4550c2c6be0502',
          '7215d406ebbd8e129f6092ee3c6c86c08d444e431bf35414613f6fbb686ab2c9',
          '01d3eae656238d3b3354a995228f8461446e0d701eb15fd71feb34afd98c3b10',
          '848a1500e563b3a6151bbd2643fefc8e04ac088312f7f812c75d67b0badbf55f',
          'deb700f0ebe34fd6c25a937629949b3c4c6c9dafdf87056190e985f189b691d2',
          '80c7742837ad9455049270303bccd55bae39a9e639b70d931191269d3a76320a',
          '689e621a3585ca018fd44ff404bc89079a0e55e9c632574e8bf8d2b1c7918911',
          '9dd1c1d121368ddefacbbbeab1626b756c0b85225a19635f743fa09d83f16369',
          'bb9d9ff64c877aedc2ec5f596e2c4806aa5b26bafa04e835624e1f8e2b87176b',
          '7ee7b95f4ae8b3e08aab5158be7fe8e71f79bcd3717594254b34fa1f3cd4611a',
          'bd25c370dbc2e62b0580bb1eea9df6a812a66d6fff7045107173a6ac5e7c6f57',
          '9f14837e4392582c3974613ed8e1acc7ed84b2ae3910ad27cb9542a3758df800',
          'f45cb3dd0fd2a31bb7b1d19baa9f4a043609debdc7c494edf73b9b56da77a642',
          '56d1854ddbc8d9ecdf5d7e2f19228314f475ee110c38fbfe3fa0b72baa41ca14',
          'b6d18ff342920bb492f810bb070a064d6031ec2c3d6fffecb6ca233c8a591e00',
          '75aa577b08b3dc3a1777c9c74cbbb79b5bfd73caaff222a28a53ce4e76cbb41c',
          '0a418f1b991744b9e77ed957b8f809e11d19201d49a2a476816c13a22420ec4c',
          'c5252f423aae35a7df6194c342b14480b0cf83c3f4d9134fe45098c2f74befd9',
          '2c724cd7e745016e6e4acccebdcc49464e13ec17ee23141c4fb2fe8004be2504',
          '82061ee410cab0e705cf38830db84ba965effc51a1e1bf43da6d39ff70ae94fb',
          '6f43d35d53ae12c86681df65342315b643026e2085e41bf8b8cc67287150c375',
          '38c16286d8c924bb05f01056a8862357e80fbdbad7570dc40aed95a57c3e4de1',
          'ce216ac7310971159adda1ac271fc6e6590d2c02da43b894ac11919084196e40',
          '984e44f2d80a93c0febf58d9ca396b5817151fc075f09333a3d387c981f75f2c',
          'a29498d225fa4b13468ff4d6cf4ae0ea4adcbd95f07ce8a843a1dee10b632f3f',
          'b508bac65989c98cd5ca664738ebd50b4fdaa06420f2c0c3b049a01a0856bd79',
          '9fd329dd22a50b44031c6c08464d46215b4b99ddc2f608f6d22d98372ccf97c8',
          '8ece0bce3a95db74077d7861f7767770af5e5746c3af011e4eec7e3d2efe2be4',
          '4457c130df49fb3cb1f8b99574b97b35208bd3d0d13b8d25d2b5884ed2cad13a',
        ],
      },
    },
  }),
createConnector: getWalletConnectConnector({ projectId }),
});

const { wallets } = getDefaultWallets();

export const wagmiConfig = getDefaultConfig({
	appName: 'Cosmic Kittens',
	projectId: projectId,
	chains: chains,
	transports: {
		[sepolia.id]: http(),
		[mainnet.id]: http(),
	},
	wallets: [
    ...wallets,
    {
      groupName: 'More',
      wallets: [
        trustWallet,
				coinbaseWallet,
				metaMaskWallet,
				walletConnectWalletCustom,
				cryptocom,
      ],
    },
  ],
})

export const walletsList = [
  {
    name: 'MetaMask',
    id: 'metaMask',
  },
	{
		name: 'Trust Wallet',
		id: 'trust',
	},
  {
    name: 'Coinbase Wallet',
    id: 'coinbase',
  },
  {
		name: 'WalletConnect',
    id: 'walletConnect',
  },
	{
		name: 'Crypto.com',
		id: 'cryptocom',
	},
];
