import { AnimatePresence, motion } from 'framer-motion';
import { PaymentProgressProps } from '.';
import { useMemo } from 'react';

export function ProgressIcon({ status }: PaymentProgressProps) {
  const cy = useMemo(() => {
    if (status === 'WAITING_PAYMENT') return 20;
    if (status === 'PAID') return 124;
    return 71;
  }, [status]);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="128"
      viewBox="0 0 40 130"
      fill="none"
    >
      <line
        x1="20.25"
        y1="19.0001"
        x2="20.25"
        y2="124"
        stroke="#e1e1e1"
        strokeWidth="0.5"
      />
      {status === 'WAITING_PAYMENT' || status === 'PAID' ? (
        <svg x="14" y="14" width="12" height="12" viewBox="0 0 12 12">
          <circle cx="6" cy="6" r="6" fill="#000000" />
          <path
            d="M8.66634 4.00012L4.99967 7.66679L3.33301 6.00012"
            stroke="#000"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ) : (
        <circle cx="20" cy="20" r="4" fill="#e1e1e1" />
      )}
      {status === 'PAID' ? (
        <svg x="14" y="118" width="12" height="12" viewBox="0 0 12 12">
          <circle cx="6" cy="6" r="6" fill="#000" />
          <path
            d="M8.66634 4.00012L4.99967 7.66679L3.33301 6.00012"
            stroke="#000"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ) : (
        <circle cx="20" cy="124" r="4" fill="#e1e1e1" />
      )}
      <AnimatePresence>
        {status !== 'PAID' && (
          <motion.g
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            key='payment-progress'
          >
            <motion.circle
              initial={{ r: 0 }}
              animate={{ r: [0, 20, 0] }}
              transition={{ duration: 2, repeat: Infinity }}
              opacity="0.1"
              cx="20"
              cy={cy}
              fill="#000"
            />
            <motion.circle
              initial={{ r: 0 }}
              animate={{ r: [0, 16, 0] }}
              transition={{ duration: 2, repeat: Infinity }}
              opacity="0.2"
              cx="20"
              cy={cy}
              fill="#000"
            />
            <motion.circle
              initial={{ r: 0 }}
              animate={{ r: [0, 11, 0] }}
              transition={{ duration: 2, repeat: Infinity }}
              opacity="0.3"
              cx="20"
              cy={cy}
              fill="#000"
            />
            <circle cx="20" cy={cy} r="6" fill="#000" />
          </motion.g>
        )}
      </AnimatePresence>
    </svg>
  );
}
