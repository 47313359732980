import { DialogClose } from '@radix-ui/react-dialog'
import { SEVEN_DAYS_IN_MS } from 'constants/sale'
import { useTimeDifference } from 'hooks/useTimeDifference'
import { UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useAppStore } from 'store'
import { useUserStore } from 'store/user'
import { Button } from 'ui-kit/Button'
import { Info } from 'ui-kit/icons/Info'
import { InfoWithGradient } from 'ui-kit/icons/InfoWithGradient'
import { Logo } from 'ui-kit/icons/Logo'
import { ClaimStatus } from 'utils'

interface DisplayClaimProps {
  statusType: ClaimStatus
  formattedTokenBalance: number
  availableBalance: number
  handleBuyMore(): void
  handleClaimSubmit(): void
  isClaiming: boolean
  value: number
  claimForm: UseFormReturn<{
    value: number
  }>
}

export const DisplayClaim: React.FC<DisplayClaimProps> = ({
  statusType,
  formattedTokenBalance,
  availableBalance,
  handleBuyMore,
  handleClaimSubmit,
  isClaiming,
  claimForm,
  value
}) => {
  const { currentRound, isLoading } = useAppStore()
  const user = useUserStore((s) => s.user)
  const { t } = useTranslation()

  const { days, hours, minutes } = useTimeDifference(
    currentRound
      ? new Date(currentRound?.finishAt).getTime() + SEVEN_DAYS_IN_MS
      : 0,
    !isLoading
  )

  return (
    <>
      <div className='font-formatDJR-m flex justify-between md:flex-col md:gap-4'>
        <div className='flex flex-col gap-1'>
          <div className='font-medium text-xs leading-5 text-[#5e5e5e]'>
            {t('wallets.balanceDialog.balance')}
          </div>

          <div className='flex items-center gap-2 font-medium text-2xl leading-snug text-[#000]'>
            <Logo width={32} height={32} />
            {statusType === ClaimStatus.Claim
              ? formattedTokenBalance
              : formattedTokenBalance}{' '}
            $SOCS
          </div>
        </div>

        {statusType === ClaimStatus.Claim && (
          <div className='flex items-end'>
            <div className='flex flex-col gap-1'>
              <div className='flex items-center gap-1 font-medium text-xs leading-5 text-[#5e5e5e]'>
                {t('wallets.balanceDialog.available')}
                <InfoWithGradient />
              </div>

              <div className='flex items-center gap-2 font-medium text-2xl leading-snug text-[#000]'>
                <Logo width={32} height={32} />
                {availableBalance} $SOCS
              </div>
            </div>
          </div>
        )}
      </div>

      {statusType === ClaimStatus.NoSale && (
        <div className='flex justify-center items-center gap-2.5 p-5 rounded-lg bg-[#f2f2f2] font-[400] text-xs leading-[0.875rem] text-[#5e5e5e]'>
          <span>
            {t('wallets.balanceDialog.claimAvailable')} {days}{' '}
            {t('wallets.balanceDialog.days')} {hours}{' '}
            {t('wallets.balanceDialog.hrs')} {minutes}{' '}
            {t('wallets.balanceDialog.min')}
          </span>
        </div>
      )}

      {user?.kicked && (
        <div className='flex items-center gap-2.5 p-[12px] rounded-lg bg-[#f2f2f2] font-[400] text-xs leading-[0.875rem] text-[#5e5e5e]'>
          <Info className='min-w-4' />

          <span>{t('wallets.balanceDialog.blocked')}</span>
        </div>
      )}

      {!!user.bonusForInvitedPercent && (
        <div className='flex items-center gap-2.5 p-[12px] rounded-lg bg-[#f2f2f2] font-[400] text-xs leading-[0.875rem] text-[#5e5e5e]'>
          <Info className='min-w-4' />

          <span>
            {t('wallets.balanceDialog.bonus')} {user.bonusForInvitedPercent}%
          </span>
        </div>
      )}

      {statusType === ClaimStatus.OngoingSale && (
        <div className='flex flex-col gap-4'>
          <div className='flex items-center gap-2.5 p-[12px] rounded-lg bg-[#f2f2f2] font-[400] text-xs leading-[0.875rem] text-[#5e5e5e]'>
            <Info className='min-w-4' />

            <span>{t('wallets.balanceDialog.launched')}</span>
          </div>

          <DialogClose asChild>
            <Button
              onClick={handleBuyMore}
              variant='primary'
              disabled={user.kicked}
              className='!font-[500] !bg-black text-white gap-[10px] disabled:opacity-50'
            >
              <Logo />
              {t('wallets.balanceDialog.buyMore')}
            </Button>
          </DialogClose>
        </div>
      )}

      {statusType === ClaimStatus.Claim && (
        <form
          onSubmit={claimForm.handleSubmit(handleClaimSubmit)}
          className='flex flex-col gap-4 pt-4'
        >
          <div>
            <div className='flex flex-col gap-1'>
              <label
                htmlFor='amountToClaim'
                className='font-medium text-xs leading-5 text-[#5e5e5e]'
              >
                {t('wallets.balanceDialog.amountClaim')}
              </label>

              <label className='flex items-center gap-4 p-3.5 rounded-lg bg-[#F2F2F2] text-[#000] cursor-text'>
                <input
                  type='number'
                  className='grow bg-transparent font-medium text-base leading-snug outline-none hero-form-field placeholder:text-[#000]'
                  placeholder='0 $SOCS'
                  id='amountToClaim'
                  min={0.01}
                  step={0.01}
                  defaultValue={value}
                  max={availableBalance}
                  {...claimForm.register('value', {
                    valueAsNumber: true
                  })}
                  readOnly
                />

                <button
                  type='button'
                  className='font-[500] text-xs leading-5 text-gradient-darker'
                  onClick={() => claimForm.setValue('value', availableBalance)}
                >
                  Max {availableBalance}
                </button>
              </label>
            </div>

            {claimForm.formState.errors.root && (
              <p className='mt-1 text-red-500'>
                {claimForm.formState.errors.root?.message}
              </p>
            )}

            {claimForm.formState.errors.value && (
              <p className='mt-1 text-red-500'>
                {claimForm.formState.errors.value?.message}
              </p>
            )}
          </div>

          <Button
            type='submit'
            variant='primary'
            disabled={isClaiming || availableBalance <= 0}
            onClick={handleBuyMore}
            className='!font-[500] text-white !bg-black gap-[10px]'
          >
            <Logo />
            {isClaiming
              ? t('wallets.balanceDialog.claiming')
              : `${t('wallets.balanceDialog.claim')} $SOCS`}
          </Button>
        </form>
      )}
    </>
  )
}

export default DisplayClaim
