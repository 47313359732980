import cn from 'classnames';
import { ReactNode } from 'react';

interface FooterBlockContainerProps {
  children: ReactNode;
  title?: string;
  classNames?: string;
  logo?: ReactNode;
}
export const FooterBlockContainer: React.FC<FooterBlockContainerProps> = ({
  children,
  title,
  classNames,
  logo,
}) => {
  return (
    <article className={cn(' relative w-full rounded-[20px]', classNames)}>
      {logo && logo}

      {title && (
        <p className='text-[1.75em] leading-[1.2857em] mb-[0.5926em]'>
          {title}
        </p>
      )}

      {children}
    </article>
  );
};
