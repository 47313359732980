import { DialogClose } from '@radix-ui/react-dialog'
import { ReactNode, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from 'ui-kit/Button'
import { Dialog } from 'ui-kit/Dialog'

type Props = {
  children?: ReactNode
}

export const FaqDialog: React.FC<Props> = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false)
  const { t } = useTranslation()

  const handleContinue = () => {
    setIsOpen(false)
  }

  return (
    <Dialog
      open={isOpen}
      trigger={children}
      onOpenChange={(val) => setIsOpen(val)}
      showCloseButton={false}
    >
      <div className='font-formatDJR-m flex flex-col max-h-[calc(100vh-2rem)] gap-6 p-[3.75rem]'>
        <h2 className='font-medium text-4xl leading-snug text-center text-black'>
          {t('faqDialog.title')}
        </h2>

        <div className='box-border font-[400] max-w-[525px] max-md:w-[calc(100vw-2rem)] md:w-[calc(100vw-2rem)] overflow-auto text-base leading-5 text-[#5E5E5E]'>
          <p>
            <strong>{t('faqDialog.deSocIsDifferent')}</strong>
            <br />
            {t('faqDialog.deSocIsDifferent.answer')}
          </p>

          <br />

          <p>
            <strong>{t('faqDialog.ensureUserSecurity')}</strong>
            <br />
            {t('faqDialog.ensureUserSecurity.answer')}
          </p>

          <br />

          <p>
            <strong>{t('faqDialog.usersInfluence')}</strong>
            <br />
            {t('faqDialog.usersInfluence.answer')}
          </p>

          <br />

          <p>
            <strong>{t('faqDialog.rewardUsers')}</strong>
            <br />
            {t('faqDialog.rewardUsers.answer')}
          </p>

          <br />

          <p>
            <strong>{t('faqDialog.needForPlatform')}</strong>
            <br />
            {t('faqDialog.needForPlatform.answer')}
          </p>

          <br />

          <p>
            <strong>{t('faqDialog.benefitUsers')}</strong>
            <br />
            {t('faqDialog.benefitUsers.answer')}
          </p>
        </div>

        <div className='grid grid-cols-1 gap-2'>
          <DialogClose asChild>
            <Button
              className='h-[3.125rem] min-w-[200px] w-[50%] mx-auto !bg-black text-white hover:bg-[#eee] !font-[400]'
              variant='DEFAULT'
              onClick={handleContinue}
            >
              {t('faqDialog.continue')}
            </Button>
          </DialogClose>
        </div>
      </div>
    </Dialog>
  )
}
