import { useTranslation } from 'react-i18next';
import { ProgressBar } from "./ProgressBar";

type ProgressInfoProps = {
  value: number;
  label: string;
  color: string;
  formattedValue: string;
};

export const ProgressInfo: React.FC<ProgressInfoProps> = ({
  value,
  label,
  color,
  formattedValue,
}) => {
  const { t } = useTranslation();
  return (
    <div className='grid grid-cols-[1.3fr_2fr_4em] gap-[3em] items-center'>
      <div className='flex flex-col items-start'>
        <p className='text-[1.125em] font-formatDJR-b'>{t(label)}</p>

        <p className='text-[#A8A8A8B2]'>{formattedValue}</p>
      </div>

      <ProgressBar
        value={value}
        color={color}
      />

      <p className='text-[1.125em] font-bold'>{Math.round(value)}%</p>
    </div>
  );
};
