import { WalletButton } from '@rainbow-me/rainbowkit';
import { walletsList } from 'configurations/wagmi';
import { walletIcons } from 'hooks/useConnectedWalletIcon';

export const WalletButtons = () => {
  return (
    <>
      {walletsList.map(({ name, id }) => (
        <WalletButton.Custom wallet={id}>
          {({ ready, connect }) => {
            return (
              <button
                type='button'
                disabled={!ready}
                onClick={connect}
                className='flex items-center h-[3.75rem] gap-3 p-3 pl-4 rounded-xl bg-[#F2F2F2] font-bold text-sm text-[#000000] disabled:opacity-50'
                style={{ zIndex: '1000' }}
              >
                <img
                  src={require(`assets/wallets/${walletIcons[name]}.png`)}
                  className='w-7 h-7'
                  alt={name}
                />
                <span className=''>{name}</span>
              </button>
            );
          }}
        </WalletButton.Custom>
      ))}
    </>
  );
};
