import { PaymentMethod } from 'constants/methods';
import { OrderStatus } from 'types/Tokens';

export enum Step {
  Referral,
  SelectAsset,
  SendDeposit,
  Success,
  Error,
}

export interface PaymentFormType {
  step: Step;
  paymentMethod: PaymentMethod;
  paymentStatus: OrderStatus;
  paymentInUSD: number;

  // Data for deposit
  orderId: string;
  payAmount: number;
  payAddress: string;
  referral: string;
}
