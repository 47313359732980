import { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import Flicking from "@egjs/react-flicking";
import { Card } from "../../components/Card";
import { experienceMockData } from "./mockData";
import { useFeatures } from "../../utils/useFeatures";
import "./styles.css";
import { countXTransition } from "../../utils/countXTransition";
import { useTranslation } from "react-i18next";

export function Experience() {
  const flicking = useRef<Flicking | null>(null);
  const [isInitialized, setIsInitialized] = useState(false);
  const [parentSize, panelWidth, panels] = useFeatures(flicking);
  const { t, i18n } = useTranslation();
  const mockData = experienceMockData();

  useEffect(() => {
    if (isInitialized && flicking.current) {
      flicking.current.resize();
    }
  }, [i18n.language, isInitialized]);

  return (
    <>
      {panels && (
        <section className='flex flex-col mb-10 mx-auto mt-[9.5em]'>
          <h2 className='font-semibold font-par-m text-[3.875em] leading-none text-center tracking-[2%] text-black'>
            {t("experience.title")}
          </h2>

          <motion.div
            initial='offscreen'
            whileInView='onscreen'
            viewport={{ once: true, amount: 0.4 }}
            className='flicking-experience relative pt-[4.375em] md:pt-[2.25em] w-full lg:text-[1rem]'
          >
            <Flicking
              ref={flicking}
              moveType='snap'
              bound={true}
              circular={false}
              cameraClass='flicking-experience flicking-camera-grid-experience'
              panelsPerView={panels}
              align={"prev"}
              bounce={panels === 3 ? "0%" : "20%"}
              onReady={() => setIsInitialized(true)}
            >
              {mockData.map(({ color, id: index, content }, i, arr) => {
                const transitionValue = countXTransition({
                  index: index,
                  count: arr.length,
                  cardWidth: flicking.current?.currentPanel.size,
                  parentSize,
                });

                return (
                  <div
                    key={index}
                    className='mr-[14px]'
                    style={{ zIndex: `${arr.length - index}` }}
                  >
                    <Card
                      parentWidth={parentSize}
                      index={index}
                      width={panelWidth}
                      transitionValue={transitionValue}
                      color={color}
                      styles={`bg-[${color}]`}
                    >
                      {content}
                    </Card>
                  </div>
                );
              })}
            </Flicking>
          </motion.div>
        </section>
      )}
    </>
  );
}
