import clsx from 'clsx';
import { ProgressIcon } from './ProgressIcon';
import { OrderStatus } from 'types/Tokens';
import { useTranslation } from 'react-i18next';

export interface PaymentProgressProps {
  status: OrderStatus;
}

export function PaymentProgress({ status }: PaymentProgressProps) {
  const { t } = useTranslation()

  return (
    <div className="flex px-[1.625rem] py-[1.125rem] gap-4 rounded-2xl bg-[#f2f2f2] font-medium text-sm text-[#8F8F8F]">
      <ProgressIcon status={status} />
      <div className="font-formatDJR-m flex flex-col justify-between leading-none pt-3">
        <div className="font-[500] text-black">{t('paymentProgress.waiting')}</div>
        <div
          className={clsx(
            status === 'PAID' && 'font-[500] text-black',
            ' transition-colors duration-200'
          )}
        >
          {t('paymentProgress.success')}
        </div>
      </div>
    </div>
  );
}
