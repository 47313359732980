import { ReactNode } from "react";
import i18next from "i18next";
import img4 from "../../../../assets/carousel/img-4.webp";
import img5 from "../../../../assets/carousel/img-5.webp";
import img6 from "../../../../assets/carousel/img-6.webp";

export type CarouselData = {
  color?: string;
  id: number;
  content: ReactNode;
};

export const carouselMockData = (): CarouselData[] => [
  {
    id: 0,
    content: (
      <>
        <img
          src={img4}
          className='w-full h-full object-contain'
          alt=''
        />
      </>
    ),
  },
  {
    color: "#FFDEEA",
    id: 1,
    content: (
      <div className='p-[1.5em]'>
        <h3 className='text-black font-par-m text-[1.75em] leading-[1.25em] font-[600] text-center mb-[.5em]'>
          {i18next.t("carouselData.syndication.title")}
        </h3>

        <ul className='text-[#5E5E5E]  tracking-[0em] track text-[1em] leading-[1.25em] [&>li:not(:last-child)]:mb-[.5em]'>
          <li>{i18next.t("carouselData.syndication.description.firstPart")}</li>

          <li>
            {i18next.t("carouselData.syndication.description.secondPart")}
          </li>
        </ul>
      </div>
    ),
  },
  {
    color: "#fff",
    id: 2,
    content: (
      <div className='p-[1.5em]'>
        <h3 className='text-black font-par-m text-[1.75em] leading-[1.25em] font-[600] text-center mb-[.5em]'>
          {i18next.t("carouselData.apiIntegration.title")}
        </h3>

        <ul className='text-[#5E5E5E]  tracking-[0em] text-[1em] leading-[1.25em] [&>li:not(:last-child)]:mb-[.5em]'>
          <li>
            {i18next.t("carouselData.apiIntegration.description.firstPart")}
          </li>

          <li>
            {i18next.t("carouselData.apiIntegration.description.secondPart")}
          </li>
        </ul>
      </div>
    ),
  },
  {
    id: 3,
    content: (
      <>
        <img
          src={img5}
          className='w-full h-full object-contain'
          alt=''
        />
      </>
    ),
  },

  {
    color: "#7B88FF",
    id: 4,
    content: (
      <div className='p-[1.5em]'>
        <h3 className='text-white font-par-m text-[1.75em] leading-[1.25em] font-[600] text-center mb-[.5em]'>
          {i18next.t("carouselData.incentivizedParticipation.title")}
        </h3>

        <ul className='text-white tracking-[0em] text-[1em] leading-[1.25em] [&>li:not(:last-child)]:mb-[.5em]'>
          <li>
            {i18next.t(
              "carouselData.incentivizedParticipation.description.firstPart"
            )}
          </li>
          <li>
            {i18next.t(
              "carouselData.incentivizedParticipation.description.secondPart"
            )}
          </li>
        </ul>
      </div>
    ),
  },

  {
    color: "#E6E6E6",
    id: 5,
    content: (
      <div className='p-[1.5em]'>
        <h3 className='text-black font-par-m text-[1.75em] leading-[1.25em] font-[600] text-center mb-[.5em]'>
          {i18next.t("carouselData.advertisementPartnerships.title")}
        </h3>

        <ul className='text-[#5E5E5E]  tracking-[0em] text-[1em] leading-[1.25em] [&>li:not(:last-child)]:mb-[.5em]'>
          <li>
            {i18next.t(
              "carouselData.advertisementPartnerships.description.firstPart"
            )}
          </li>
          <li>
            {i18next.t(
              "carouselData.advertisementPartnerships.description.secondPart"
            )}
          </li>
        </ul>
      </div>
    ),
  },
  {
    id: 6,
    content: (
      <>
        <img
          src={img6}
          className='w-full h-full object-contain'
          alt=''
        />
      </>
    ),
  },
  {
    color: "#5a546b",
    id: 7,
    content: (
      <div className='p-[1.5em]'>
        <h3 className='text-white font-par-m text-[1.75em] leading-[1.25em] font-[600] text-center mb-[.5em]'>
          {i18next.t("carouselData.verifiabilityThrough.title")}
        </h3>

        <ul className='text-white  tracking-[0em] text-[1em] leading-[1.25em] [&>li:not(:last-child)]:mb-[.5em]'>
          <li>
            <strong>
              {i18next.t("carouselData.verifiabilityThrough.records")}
            </strong>{" "}
            {i18next.t("carouselData.verifiabilityThrough.records.description")}
          </li>
          <li>
            <strong>
              {i18next.t("carouselData.verifiabilityThrough.smartContracts")}
            </strong>{" "}
            {i18next.t(
              "carouselData.verifiabilityThrough.smartContracts.description"
            )}
          </li>
          <li>
            <strong>
              {i18next.t("carouselData.verifiabilityThrough.auditableData")}
            </strong>{" "}
            {i18next.t(
              "carouselData.verifiabilityThrough.auditableData.description"
            )}
          </li>
        </ul>
      </div>
    ),
  },
];
