import { scrollTo } from 'utils';
import { MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  place: 'header' | 'burger';
  setIsOpen?: (val: boolean) => void;
};
export const auditLink = `${window.location.origin}/audit_desoc.pdf`;

export const Navigation: React.FC<Props> = ({ place, setIsOpen }) => {
  const handleClick = (e: MouseEvent, id: string) => {
    e.preventDefault();
    setIsOpen && setIsOpen(false);
    scrollTo(document.getElementById(id));
  };

  const { t } = useTranslation();

  return (
    <nav
      className={
        place === 'burger'
          ? 'flex flex-col items-center gap-[2rem] font-medium [&_a]:text-[1rem] [&_a]:whitespace-nowrap px-4 leading-5 text-[#fff]'
          : 'lg:hidden flex items-center gap-[2rem] font-medium [&_a]:text-[1em] [&_a]:whitespace-nowrap px-4 leading-5 text-[#fff]'
      }
    >
      <a href='#features' onClick={(e) => handleClick(e, 'features')}>
        {t('navigation.features')}
      </a>
      <a href='#faq' onClick={(e) => handleClick(e, 'roadmap')}>
        {t('navigation.roadmap')}
      </a>
      <a href='#tokenomics' onClick={(e) => handleClick(e, 'tokenomics')}>
        {t('navigation.tokenomics')}
      </a>
      <a
        href='https://desoc.gitbook.io/desoc'
        target='_blank'
        aria-label='Docs'
        rel='noopener noreferrer'
      >
        {t('navigation.whitePaper')}
      </a>
      <a
        href={auditLink}
        target='_blank'
        aria-label='Audit'
        rel='noopener noreferrer'
      >
        {t('navigation.audit')}
      </a>
    </nav>
  );
};
