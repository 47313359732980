import axios, { AxiosInstance } from 'axios';
import { API_URL } from 'constants/api';
import { appStore } from 'store';
import { userStore } from 'store/user';
import { BonusProgram, ReferralProgram, Round, Settings } from 'types';
import { Order, OrdersResponse, SaleProgressResponse, IPaymentLimit } from 'types/Tokens';

export class TokensService {
  private _api: AxiosInstance;

  constructor() {
    this._api = axios.create({
      baseURL: API_URL,
      withCredentials: true,
    });
  }

  async getCurrentRound() {
    try {
      appStore.setState({ isLoading: true });
      const url = '/api/rounds/current';
      const { data } = await this._api.get<Round>(url);

      appStore.setState({ currentRound: data, isLoading: false });

      return data;
    } catch (error) {
      console.warn(error);
    }
    return null;
  }

  async sendMessage(
    name: string,
    email: string,
    message: string
  ): Promise<void> {
    try {
      const url = '/api/mail/send-message';
      await this._api.post(url, { name, email, message });
    } catch (error) {
      console.warn(error);
    }
  }

  async sendOrder(
    tokensAmount: number,
    paymentMethod: string,
    referralCode: string | undefined
  ): Promise<OrdersResponse | null> {
    try {
      const accessToken = localStorage.getItem('accessToken');

      if (!accessToken) {
        throw new Error('No access token');
      }

      const { currentRound } = appStore.getState();

      if (!currentRound) return null;

      const { data } = await this._api.post<OrdersResponse>(
        '/api/orders',
        { tokensAmount, paymentMethod, roundId: currentRound.id, referralCode },
        {
          headers: {
            authorization: accessToken,
          },
        }
      );

      return data;
    } catch (error) {
      console.warn(error);
      throw error;
    }
  }

  async getOrderById(orderId: string): Promise<Order | null> {
    try {
      const accessToken = localStorage.getItem('accessToken');

      if (!accessToken) {
        throw new Error('No access token');
      }

      const { data } = await this._api.get<Order>(`/api/orders/${orderId}`, {
        headers: {
          authorization: accessToken,
        },
      });

      return data;
    } catch (error) {
      console.warn(error);
      throw error;
    }
  }

  async getSaleProgress(): Promise<SaleProgressResponse | null> {
    try {
      const url = '/api/balances/sale-progress';
      const { data } = await this._api.get<SaleProgressResponse>(url);

      return data;
    } catch (error) {
      console.warn(error);
    }

    return null;
  }

  async getTokensBalance(): Promise<number> {
    try {
      const url = '/api/balances/my-tokens';

      const accessToken = localStorage.getItem('accessToken');

      if (!accessToken) {
        throw new Error('No access token');
      }

      const { data } = await this._api.get<{ amount: number }>(url, {
        headers: {
          authorization: accessToken,
        },
      });

      return data.amount;
    } catch (error) {
      console.warn(error);
    }
    return 0;
  }

  async getMarkleTreeProof(): Promise<string[] | null> {
    const url = '/api/merkle-tree/proof';
    const accessToken = localStorage.getItem('accessToken');

    if (!accessToken) {
      throw new Error('No access token');
    }

    const { data } = await this._api.get<string[]>(url, {
      headers: {
        authorization: accessToken,
      },
    });

    return data;
  }

  async getSalesEndTimestamp(): Promise<number> {
    try {
      const url = '/api/sale/end-timestamp';
      const { data } = await this._api.get<{ endTimestamp: number }>(url);

      appStore.setState({ endOfSalesTimestamp: data.endTimestamp });

      return data.endTimestamp;
    } catch (error) {
      console.warn(error);
    }
    return 0;
  }

  async getCurrentBonus() {
    try {
      const url = '/api/bonuses/current';
      const { data } = await this._api.get<BonusProgram>(url);

      appStore.setState({ currentBonus: data });

      return data;
    } catch (error) {
      console.warn(error);
    }
    return null;
  }

  async getCurrentReferralProgram() {
    try {
      const url = '/api/referral-bonuses/current';
      const { data } = await this._api.get<ReferralProgram>(url);

      appStore.setState({ currentReferralProgram: data });

      return data;
    } catch (error) {
      console.warn(error);
    }
    return null;
  }

  async isRefAvailable() {
    try {
      const token = localStorage.getItem('accessToken');
      const { data } = await this._api.get<{ available: boolean }>(
        '/api/referral-bonuses/check-available',
        {
          headers: {
            authorization: token,
          },
        }
      );

      const currentReferralProgram = await this.getCurrentReferralProgram();

      userStore.setState({
        canEnterRefCode: currentReferralProgram?.enabled && data.available,
      });

      return data.available;
    } catch (error) {
      console.warn(error);
    }
    return false;
  }

  async isRefCodeValid(refCode: string) {
    const token = localStorage.getItem('accessToken');
    const { data } = await this._api.get<{ available: boolean }>(
      `/api/referral-bonuses/check-code`,
      {
        params: {
          code: refCode,
        },
        headers: {
          authorization: token,
        },
      }
    );

    return data.available;
  }

  async getProgress() {
    try {
      const { setProgress } = appStore.getState();

      const url = '/api/sale/progress';
      const { data } = await this._api.get<{
        total: number;
        sold: number;
      }>(url);

      setProgress(data.total, data.sold);

      return data;
    } catch (error) {
      console.warn(error);
    }
  }

  async getSettings() {
    try {
      const url = '/api/settings';
      const { data } = await this._api.get<Settings>(url);

      appStore.setState({
        settings: data,
      });

      return data;
    } catch (error) {
      console.warn(error);
    }
  }

  async getPaymentLimit(token: string): Promise<IPaymentLimit> {
    const url = '/api/now-payments/limits';

    const accessToken = localStorage.getItem('accessToken');

    if (!accessToken) {
      throw new Error('No access token');
    }

    const { data } = await this._api.get<IPaymentLimit>(url, {
      params: {
        token,
      },
      headers: {
        authorization: accessToken,
      },
    });

    return data;
  }

  async getBanner() {
    try {
      const url = '/api/banners/current'
      const { data } = await this._api.get(url)
      appStore.setState({ banner: data })
    } catch (error) {
      console.warn(error)
      throw error
    }
  }
}

export const tokensApi = new TokensService();
