import ErrorIcon from 'assets/error.webp';
import { useFormContext } from 'react-hook-form';
import { Button } from 'ui-kit/Button';
import { PaymentFormType, Step } from './scheme';
import { DialogClose } from '@radix-ui/react-dialog';
import { useTranslation } from 'react-i18next';

export function ErrorResult() {
  const form = useFormContext<PaymentFormType>();
  const { t } = useTranslation()

  if (!form)
    throw new Error('This component must be used within a <FormProvider />');

  return (
    <>
      <div className='flex justify-center'>
        <img src={ErrorIcon} className='w-[69px] h-[79px]' alt='Success icon' />
      </div>
      <div className='font-formatDJR-m flex flex-col items-center gap-2'>
        <h2 className='font-[500]  text-2xl leading-snug text-[#000]'>
          {t('errorResult.title')}
        </h2>
      </div>

      <DialogClose asChild>
        <Button
          className='font-formatDJR-m font-[500] h-[3.375rem] text-white !bg-black'
          variant='primary'
        >
          {t('errorResult.sendButton')}
        </Button>
      </DialogClose>
    </>
  );
}
