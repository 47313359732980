import { FormProvider, useForm } from 'react-hook-form';
import { Dialog } from 'ui-kit/Dialog';
import { Wallets } from './Wallets';
import { Email } from './Email';

interface EmailAddingDialogProps {
  isOpen: boolean;
  onReturnToBy(): void;
  onOpenChange(val: boolean): void;
}

export enum Step {
  Wallets,
  Email,
  Code,
  Success,
}

export interface RegistrationFormType {
  step: Step;
  email: string;
  isLockedModal: boolean;
  code: string;
}

export function EmailAddingDialog({
  isOpen,
  onReturnToBy,
	onOpenChange,
}: EmailAddingDialogProps) {
  const form = useForm<RegistrationFormType>({
    defaultValues: {
      step: Step.Wallets,
      email: '',
      isLockedModal: false,
      code: '',
    },
  });

  const onChangeIsLockedModal = (val: boolean) => {
    form.setValue('isLockedModal', val);
  };

  const step = form.watch('step');
  const isLockedModal = form.watch('isLockedModal');

  const handleOnOpenChange = (val: boolean) => {
    onOpenChange(val);
    if (val) return;

    form.reset();
  };

  return (
    <Dialog
      open={isOpen}
      onOpenChange={handleOnOpenChange}
      disableInteractOutside={isLockedModal}
      contentProps={{
        onInteractOutside: (e) => {
          if (isLockedModal) {
            e.preventDefault();
            e.stopPropagation();
          }
        },
      }}
      modal={false}
      showCloseButton={!isLockedModal}
    >
      <FormProvider {...form}>
        <div className='font-formatDJR-b flex flex-col gap-6 w-screen max-w-[517px] p-10 pt-[3.125rem] sm:w-[300px] max-h-[98vh] lg:overflow-y-scroll'>
          {step === Step.Wallets && (
            <Wallets
              setIsLocked={onChangeIsLockedModal}
            />
          )}

          {step === Step.Email && <Email onReturnToBy={onReturnToBy}/>}
        </div>
      </FormProvider>
    </Dialog>
  );
}
