import { SVGAttributes } from 'react';

export function InfoWithGradient(props: SVGAttributes<SVGElement>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='14'
      height='15'
      viewBox='0 0 14 15'
      fill='none'
      {...props}
    >
      <g id='info'>
        <path
          d='M6.99935 13.3333C10.221 13.3333 12.8327 10.7216 12.8327 7.49996C12.8327 4.2783 10.221 1.66663 6.99935 1.66663C3.77769 1.66663 1.16602 4.2783 1.16602 7.49996C1.16602 10.7216 3.77769 13.3333 6.99935 13.3333Z'
          fill='black'
        />
        <path
          d='M7 9.83333V7.5'
          stroke='white'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M7 5.16663H7.00583'
          stroke='white'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
    </svg>
  );
}
