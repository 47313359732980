import { BonusProgram, IBanner, ReferralProgram, Round, Settings } from 'types';
import { create } from 'zustand';

export interface AppStore {
  isLoading: boolean;
  endOfSalesTimestamp: number;
  currentRound: Round | null;
  setCurrentRound: (round: Round) => void;
  setEndOfSalesTimestamp: (timestamp: number) => void;
  setIsLoading: (isLoading: boolean) => void;
  banner: IBanner | null;

  currentBonus: BonusProgram | null;
  currentReferralProgram: ReferralProgram | null;
  totalPool: number;
  sold: number;
  settings: Settings | null;

  setProgress: (totalPool: number, sold: number) => void;
}

export const appStore = create<AppStore>((set) => ({
  
  endOfSalesTimestamp: 0,
  currentRound: null,
  setCurrentRound: (round) => set({ currentRound: round }),
  setEndOfSalesTimestamp: (timestamp) => set({ endOfSalesTimestamp: timestamp }),
  isLoading: false,
  setIsLoading: (isLoading) => set({ isLoading }),
  banner: null,
  currentBonus: null,
  currentReferralProgram: null,
  totalPool: 0,
  sold: 0,
  settings: null,
  setProgress: (totalPool, sold) => set({ totalPool, sold }),
}));

export const useAppStore = appStore;
