import {
  PopoverClose,
  PopoverContent,
  PopoverPortal,
  PopoverTrigger,
  Root,
} from "@radix-ui/react-popover";
import { AnimatePresence } from "framer-motion";
import { ReactNode, useState } from "react";
import { Button } from "ui-kit/Button";
import { motion } from "framer-motion";
import { wagmiConfig } from "configurations/wagmi";
import { disconnect, getAccount } from "@wagmi/core";
import { useTranslation } from "react-i18next";

interface WalletPopoverProps {
  children?: ReactNode;
}

export function WalletPopover({ children }: WalletPopoverProps) {
  const [isOpen, setIsOpen] = useState(false);
  const { connector } = getAccount(wagmiConfig);
  const { t } = useTranslation();

  return (
    <Root
      open={isOpen}
      onOpenChange={(val) => setIsOpen(val)}
    >
      <PopoverTrigger asChild>{children}</PopoverTrigger>
      <AnimatePresence>
        {isOpen && (
          <PopoverPortal forceMount>
            <PopoverContent
              sideOffset={8}
              asChild
            >
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className='relative z-10'
              >
                <PopoverClose asChild>
                  <Button
                    className='px-[1em] h-[3.375rem] text-[0.875rem] lg:h-[2.025rem] lg:text-[.6rem] full-hd-bp:h-[4.4888rem]'
                    onClick={() =>
                      disconnect(wagmiConfig, {
                        connector,
                      })
                    }
                  >
                    {t("header.walletPopover.disconnect")}
                  </Button>
                </PopoverClose>
              </motion.div>
            </PopoverContent>
          </PopoverPortal>
        )}
      </AnimatePresence>
    </Root>
  );
}
