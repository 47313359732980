import {
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@radix-ui/react-accordion';
import classNames from 'classnames';
import { ReactNode } from 'react';
import { AccordionTriggerIcon } from 'ui-kit/icons/AccordionTriggerIcon';

interface FAQAccordionProps {
  value: string;
  title: string;
  children: ReactNode;
	breaklWords?: boolean
}

export function FAQAccordion({ value, title, children, breaklWords }: FAQAccordionProps) {
  return (
    <AccordionItem value={value} className='text-left rounded-2xl bg-white'>
      <AccordionTrigger className='AccordionContent transition-all text-left justify-between flex items-center w-full gap-[1em] p-[1.5em] sm:p-[.8em] font-medium text-[1em] leading-[1.9em] tracking-[0.0225em]'>
        {title}
        <AccordionTriggerIcon
          color={'white'}
          fill='#000'
          className='min-w-[1.75em] min-h-[1.75em] transition-all'
        />
      </AccordionTrigger>
      <AccordionContent className='pl-[1.5em] sm:pl-[.8em] pr-[4em] sm:pr-[2.5em] font-medium text-[.875em] leading-[1.625em] text-[#959595] accordion-animation'>
        <div className={classNames('pb-6', breaklWords && 'break-words')}>{children}</div>
      </AccordionContent>
    </AccordionItem>
  );
}
