import { DialogClose } from '@radix-ui/react-dialog';
import { Button } from 'ui-kit/Button';
import { Dialog } from 'ui-kit/Dialog';

type Props = {
  isOpen: boolean;
  handleOpenDisclaimer: (value: boolean) => void;
};

export const DisclaimerDialog: React.FC<Props> = ({
  isOpen,
  handleOpenDisclaimer,
}) => {
  const handleLeave = () => {
    window.open('/', '_self');
    window.close();
  };

  const handleContinue = () => {
    localStorage.setItem('isDisclaimerShowed', 'true');
    handleOpenDisclaimer(false);
  };

  return (
    <Dialog
      open={isOpen}
      onOpenChange={(val) => handleOpenDisclaimer(val)}
      showCloseButton={false}
    >
      <div className='font-formatDJR-m flex flex-col max-h-[calc(100vh-2rem)] gap-6 p-[3.75rem]'>
        <h2 className='font-medium text-4xl leading-snug text-center text-black'>
          Website Disclaimer
        </h2>

        <div className='box-border font-[400] max-w-[525px] max-md:w-[calc(100vw-2rem)] md:w-[calc(100vw-2rem)] overflow-auto text-base leading-5 text-[#5E5E5E]'>
          <p>
            <strong>Terms of Purchase and Compliance with Regulations</strong>
            <br />
            When you buy DeSoc tokens (SOCS), it means you agree to our Terms of
            Service, Privacy Policy, and fully accept the DeSoc Cryptocurrency
            Risk Disclaimer. By purchasing SOCS, you certify that you are
            neither a resident nor a citizen of the United States, Canada,
            England any country facing sanctions, or any area where buying SOCS
            is prohibited by law. Our website has an up-to-date list of these
            countries. We regularly revise this list and our terms, so please
            consult the most current version for accurate information.
          </p>

          <br />

          <p>
            <strong>Nature and Intended Use of SOCS Tokens</strong>
            <br />
            SOCS tokens are designed to aid community initiatives and support
            creators. They are to be seen as utility tokens and not investment
            vehicles. We make no claims about SOCS having inherent monetary
            value or promise any potential increase in value. The value of the
            token post-presale is not guaranteed. We will not be held
            accountable for errors or financial losses incurred during the use
            of SOCS. The use of SOCS is at the sole risk and discretion of the
            user.
          </p>

          <br />

          <p>
            <strong>Staking and Involvement with External Entities</strong> SOCS
            can be staked in contracts associated with various external parties,
            including individuals, groups, protocols, and creators, none of whom
            are officially linked to or endorsed by DeSoc. Although efforts are
            made to validate the legitimacy of each smart contract or wallet
            address tied to these staking agreements, we cannot ensure total
            accuracy or authenticity. We do not promise to continuously monitor
            or update the list of these external entities.
          </p>

          <br />

          <p>
            <strong>Platform Usage and Service Consistency</strong>
            <br />
            Engaging with the DeSoc platform and its services indicates an
            understanding that they are offered without any warranties or
            assurances of regular updates, improvements, or maintenance. The
            availability and continuity of our services and user interface are
            not guaranteed and can be altered or terminated without notice.
            Nevertheless, the foundational smart contract for SOCS tokens is
            persistently available on the blockchain, functioning independently
            from our platform or interface. For a comprehensive understanding,
            please review our full{' '}
            <a
              href='https://desoc.gitbook.io/privacy-policy/'
              target='_blank'
              rel='noopener noreferrer'
              className='underline'
            >
              Privacy Policy
            </a>{' '}
            &{' '}
            <a
              href='https://desoc.gitbook.io/terms-and-conditions/'
              target='_blank'
              rel='noopener noreferrer'
              className='underline'
            >
              Terms and Conditions
            </a>
            .
          </p>
        </div>

        <div className='grid grid-cols-2 gap-2'>
          <Button
            variant='DEFAULT'
            className='h-[3.125rem] text-black !bg-black !bg-opacity-5 hover:bg-[#eee] !font-[400]'
            onClick={handleLeave}
          >
            Leave the website
          </Button>
          <DialogClose asChild>
            <Button
              className='h-[3.125rem] !bg-black text-white hover:bg-[#eee] !font-[400]'
              variant='DEFAULT'
              onClick={handleContinue}
            >
              Continue to website
            </Button>
          </DialogClose>
        </div>
      </div>
    </Dialog>
  );
};
