export enum ErrorType {
	FormatEmail = 'You have entered an invalid email address.',
	TooManyRequests = 'Too many requests. Wait a few minutes.',
	InvalidCode = 'Oops... This code seems to be wrong, please check it or resend to get a new one.',
	EmailConnectedByAnotherUser = 'This email is connected by another user',
}

export const errorMessageHandler = (message: string | undefined) => {
	switch (message) {
		case 'email must be an email': 
		return ErrorType.FormatEmail;
		
		case 'ThrottlerException: Too Many Requests': 
		return ErrorType.TooManyRequests;
		
		case 'Invalid verification code': 
		return ErrorType.InvalidCode;
		
		case 'This email is connected by another user': 
		return ErrorType.EmailConnectedByAnotherUser;

	default:
		return message || '';
	}
}