import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { RegistrationFormType, Step } from '.';
import { Button } from 'ui-kit/Button';
import { Info } from 'ui-kit/icons/Info';

import errorIcon from '../../../../assets/vectors/form-error-circle.svg';
import { useDisconnect } from 'wagmi';
import { authApi } from 'services/auth';
import { AxiosError } from 'axios';
import { ErrorType, errorMessageHandler } from 'utils/errorMessages';
import { useTranslation } from 'react-i18next';

type Props = {
  onReturnToBy(): void;
};

export const Email: React.FC<Props> = ({ onReturnToBy }) => {
  const [isSenging, setIsSending] = useState(false);
  const form = useFormContext<RegistrationFormType>();
  const email = form.watch('email');
  const { disconnect } = useDisconnect();
  const { t } = useTranslation()

  const handleCleanEmail = () => {
    form.setValue('email', '');
  };

  const handleSendEmail = async () => {
    if (!email) return;

    form.clearErrors();

    try {
      setIsSending(true);

      const res = await authApi.connectEmail({ email: email.toLowerCase() });

      if (res) {
        onReturnToBy();
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        const message = errorMessageHandler(error.response?.data.message);

        form.setError('email', { message });
      }
      console.warn(error);
    } finally {
      setIsSending(false);
    }
  };

  const onReselectWallet = () => {
    disconnect();
    form.clearErrors();
    form.setValue('step', Step.Wallets);
  };

  useEffect(() => {
    form.clearErrors();
  }, [email]);

  const errorMessage = form.formState.errors.email?.message;

  return (
    <>
      <h2 className='font-formatDJR-b text-[1.75rem] leading-snug text-center text-black'>
        {t('enterEmail')}
      </h2>

      <div className='flex flex-col'>
        <label
          htmlFor='Email'
          className='font-medium text-xs leading-5 text-[#5e5e5e]'
        >
          {t('emailAddress')}
        </label>

        <label className='flex items-center justify-between p-3.5 rounded-lg bg-[#F2F2F2] text-[#000] cursor-text'>
          <input
            type='email'
            className='grow bg-transparent font-medium text-base leading-snug outline-none hero-form-field placeholder:text-[#7d7d7d]'
            placeholder='user@example.com'
            id='Email'
            defaultValue={email}
            {...form.register('email')}
          />

          {email && (
            <img
              src={errorIcon}
              alt='status'
              onClick={handleCleanEmail}
              className='cursor-pointer'
            />
          )}
        </label>

        {errorMessage && (
          <div className='flex items-center gap-2.5 p-[12px] mt-[1.5rem] rounded-lg bg-[#FFE7EA] font-[400] text-xs leading-[0.875rem] text-[#92304D]'>
            <Info className='min-w-4' stroke='#92304D' />

            <span>{errorMessage}</span>
          </div>
        )}

        <Button
          type='submit'
          variant='primary'
          disabled={isSenging || !email}
          onClick={
            errorMessage === ErrorType.EmailConnectedByAnotherUser
              ? onReselectWallet
              : handleSendEmail
          }
          className='!font-[500] h-[3.375rem] mt-[1rem] text-white !bg-black gap-[10px]'
        >
          {errorMessage === ErrorType.EmailConnectedByAnotherUser
            ? t('connectAnotherWallet')
            : t('confirm') }
        </Button>
      </div>
    </>
  );
};
