import { RainbowKitProvider } from '@rainbow-me/rainbowkit';
import { wagmiConfig } from 'configurations/wagmi';
import { WagmiProvider } from 'wagmi';
import { LandingView } from './screens/Landing';
import { useLayoutEffect } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { tokensApi } from 'services/tokens';

function App() {
  useLayoutEffect(() => {
    tokensApi.getCurrentRound();
    tokensApi.getCurrentBonus();
    tokensApi.getCurrentReferralProgram();
    tokensApi.getProgress();
    tokensApi.getSettings();
    tokensApi.getBanner();
  }, []);

  const queryClient = new QueryClient();

  return (
    <WagmiProvider config={wagmiConfig}>
      <QueryClientProvider client={queryClient}>
        <RainbowKitProvider modalSize='compact'>
          <LandingView />
        </RainbowKitProvider>
      </QueryClientProvider>
    </WagmiProvider>
  );
}

export default App;
