import { FooterBlockContainer } from './FooterBlockContainer';
import { Socials } from './components/Socials';
import { FaqDialog } from '../FaqDialog';
import { FeedbackForm } from './components/FeedbackForm';
import { useTranslation } from 'react-i18next';

type Props = {
  handleOpenDisclaimer: (val: boolean) => void;
};

export const Footer: React.FC<Props> = ({ handleOpenDisclaimer }) => {
  const { t } = useTranslation()

  return (
    <footer className=' font-formatDJR-m pt-[3.4375em] pb-[1em] bg-black text-white rounded-t-[40px]'>
      <div className='max-w-[1440px] min-w-[320px] full-hd-bp:max-w-[1920px] mx-auto px-[5.625em] md:px-[2.625em]'>
        <section className='grid grid-cols-[1fr_1fr] lg:grid-cols-1 gap-[1.5em] h-fit'>
          <div className='flex flex-col  w-full justify-between gap-8'>
            <Socials styles='flex flex-col gap-[2em] justify-start lg:items-center items-start max-w-[100%] ' />

            <FooterBlockContainer classNames=''>
              <div className='flex text-[#fff] text-opacity-80 justify-between font-formatDJR-r font-[400] flex-wrap text-[.875em] lg:justify-center gap-2 lg:max-w-full max-w-[508px]'>
                <FaqDialog>
                  <span className='cursor-pointer text-opacity-80'>{t('faq')}</span>
                </FaqDialog>

                <a
                  href='https://desoc.gitbook.io/desoc'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  {t('docs')}
                </a>

                <a
                  href='https://desoc.gitbook.io/terms-and-conditions/'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  {t('terms')}
                </a>

                <a
                  href='https://desoc.gitbook.io/privacy-policy/'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  {t('privacy')}
                </a>

                <a
                  href='https://desoc.gitbook.io/disclaimer'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  {t('disclaimer')}
                </a>
              </div>
            </FooterBlockContainer>
          </div>

          <FooterBlockContainer
            title={t('support')}
            classNames='h-full flex flex-col [&>p]:mb-0 lg:[&>p]:mb-[0.5926em] justify-between lg:text-center lg:justify-self-center max-w-[508px] justify-self-end'
          >
            <FeedbackForm />
          </FooterBlockContainer>
        </section>

        <p className='font-formatDJR-r text-[.875em] text-center mt-[2.5625em]'>
          <strong>{t('disclaimer')}:</strong> {t('disclaimer.description')}
        </p>

        <p className='font-formatDJR-r text-[.875em] text-center mt-[2.5625em]'>
          @Copyright DeSoc 2024
        </p>
      </div>
    </footer>
  );
};
