import { Root as AccordionRoot } from '@radix-ui/react-accordion';
import { RoadmapAccordion } from './RoadmapAccordion';
import { roadmapMockData } from './data';
import './styles.css';
import { useTranslation } from 'react-i18next';

export function DesocRoadmap() {
  const { t } = useTranslation()
  const roadmapData = roadmapMockData()

  return (
    <div className='font-formatDJR-m  pb-[7.4375em] text-black' id='roadmap'>
      <div className='px-[4em] md:px-0 mt-[9em] mb-[3.125em] flex justify-start items-center lg:flex-col lg:items-start'>
        <h2 className='font-par-m font-semibold text-[3.871em] leading-[1.2593em]'>
          {t('desocRoadmap.title')}
        </h2>
      </div>

      <div className='flex items-start'>
        <div className='grow ml-auto w-full'>
          <AccordionRoot
            type='single'
            collapsible
            className=''
            orientation='horizontal'
          >
            <div className='grid xl-1440:grid-cols-4 gap-[1em] sm:grid-cols-1 grid-cols-2'>
              {roadmapData.map(
                ({ sectionName, accordions, color, colorText }, i) => (
                  <section
                    className='border-transparent bg-white  p-8 rounded-[2rem]'
                    key={`roadmap-${i}`}
                  >
                    <div
                      style={{
                        backgroundColor: `${color}`,
                        color: `${colorText}`,
                      }}
                      className='flex justify-center items-center  mb-[1.1429em] px-[1em] py-[0.5em] text-[#F2F2F2] rounded-full font-medium text-[.875em] leading-none tracking-[0.0175em]'
                    >
                      {sectionName}
                    </div>

                    <div className='flex flex-col gap-[1.7143em]'>
                      {accordions.map(({ title, content }, j) => (
                        <RoadmapAccordion
                          key={`roadmap-${i}-${j}`}
                          title={title}
                          value={`roadmap-${i}-${j}`}
                        >
                          {content}
                        </RoadmapAccordion>
                      ))}
                    </div>
                  </section>
                )
              )}
            </div>
          </AccordionRoot>
        </div>
      </div>
    </div>
  );
}
