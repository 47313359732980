import { useAccount } from 'wagmi';
import { Button } from 'ui-kit/Button';
import { ConnectWalletDialog } from '../ConnectWalletDialog';
import { Root as AccordionRoot } from '@radix-ui/react-accordion';
import { Accordion } from './Accordion/Accordion';
import { roadmapMockData } from './Accordion/data';
import { audit } from 'constants/links';
import { useTranslation } from 'react-i18next';
import { auditLink } from '../Header/components/Navigation';

interface FeaturesSocsProps {}

export const FeaturesSocs: React.FC<FeaturesSocsProps> = () => {
  const { isConnected, address } = useAccount();
  const { t } = useTranslation();
  const handleBuyMore = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  const roadmapData = roadmapMockData();

  const handleRedirect = (url: string) => {
    window.open(url, '_blank');
  };

  return (
    <div
      id='features'
      className='mt-[11.1875em] pl-[4em] pt-[4.5em] pr-[5em] md:px-4 lg:py-8 pb-[4.313em] bg-white rounded-[40px]'
    >
      <div className='grid grid-cols-2 gap-[4em] lg:gap-[2em] lg:grid-cols-1'>
        <div>
          <p className='font-[600] font-par-m text-[3.875em]'>
            {t('featuresSocs.title')}
          </p>

          {isConnected && address ? (
            <div className='flex gap-2'>
              <Button
                className='h-[3.857em] w-[16.5714em] mt-10 lg:mt-5 !bg-[#000] text-[#fff]'
                variant='primary'
                onClick={handleBuyMore}
              >
                {t('featuresSocs.buy')} $SOCS
              </Button>
            </div>
          ) : (
            <ConnectWalletDialog>
              <Button
                className='h-[3.857em] w-[16.5714em] mt-10 lg:mt-5 !bg-[#000] text-[#fff]'
                variant='primary'
              >
                {t('featuresSocs.buy')} $SOCS
              </Button>
            </ConnectWalletDialog>
          )}

          <Button
            className='h-[3.857em] w-[16.5714em] mt-10 lg:mt-5 !bg-[#000] text-[#fff]'
            variant='primary'
            onClick={() => handleRedirect(auditLink)}
          >
            {t('featuresSocs.audit')}
          </Button>
        </div>

        <div className='ml-auto pt-[1.75em] w-full'>
          <AccordionRoot type='single' collapsible className=''>
            <section className='border-transparent bg-white  rounded-[2em]'>
              <div className='flex flex-col gap-[3.75em]'>
                {roadmapData.map(({ sectionName, accordions, color }, j) => (
                  <Accordion
                    color={color}
                    key={`roadmap${j}`}
                    title={sectionName}
                    value={`roadmap${j}`}
                  >
                    <ul className='pl-[3.5em] font-medium text-[1em] leading-[1.625em] text-[#5E5E5E]'>
                      {accordions.map(({ title, content }, i) => {
                        return (
                          <li key={`roadmap-${j}-${i}`} className='mt-[1.6em]'>
                            <p>
                              <strong>{title}</strong>{' '}
                              <span>{`${content}`}</span>
                            </p>
                          </li>
                        );
                      })}
                    </ul>
                  </Accordion>
                ))}
              </div>
            </section>
          </AccordionRoot>
        </div>
      </div>
    </div>
  );
};
