import { InputHTMLAttributes, forwardRef } from "react";
import './styles.css';

export const FormField = forwardRef<
  HTMLInputElement,
  InputHTMLAttributes<HTMLInputElement>
>((props, ref) => {
  return (
    <input
      type="text"
      className="autofill-fix text-[0.875em] font-[400] h-[3.714em] px-6 py-4 rounded-2xl bg-white bg-opacity-5 outline-none border border-white border-opacity-20 placeholder:text-white"
      ref={ref}
      {...props}
    />
  );
});
