import { AnimatePresence, motion } from 'framer-motion';
import { useState, useEffect, useMemo } from 'react';
import { CarouselData, carouselMockData } from './mockData';
import { useAccount } from 'wagmi';
import { Button } from 'ui-kit/Button';
import { ConnectWalletDialog } from '../ConnectWalletDialog';
import { useWindowSize } from 'hooks/useWindowSize';
import './styles.css';
import { useTranslation } from 'react-i18next';

type CarouselProps = {};

const INITIAL_ACTIVE_INDEX = 0;

enum DIRECTION {
  toLeft = 0,
  toRigh = 1,
}

export const Carousel: React.FC<CarouselProps> = () => {
  const { isConnected, address } = useAccount();
  const [[activeIndex, direction], setActiveIndex] = useState([
    INITIAL_ACTIVE_INDEX,
    DIRECTION.toLeft,
  ]);
  const { width } = useWindowSize();
  const { t } = useTranslation()
  const mockData = carouselMockData()

  const carouselSetup = useMemo(() => {
    if (width >= 1024) return { items: 5, center: 2 };

    if (width < 1024) return { items: 3, center: 1 };

    return { items: 5, center: 2 };
  }, [width]);

  const indexInArrayScope = activeIndex;

  const visibleItems: CarouselData[] = [...mockData, ...mockData].slice(
    indexInArrayScope,
    indexInArrayScope + carouselSetup.items
  );

  if (activeIndex === mockData.length) {
    setActiveIndex((prevIndex) => [INITIAL_ACTIVE_INDEX, DIRECTION.toLeft]);
  }

  useEffect(() => {
    let intervalId = setInterval(() => {
      setActiveIndex((prevIndex) => [prevIndex[0] + 1, DIRECTION.toLeft]);
    }, 4000);

    const handleVisibilityChange = () => {
      if (document.hidden) {
        clearInterval(intervalId);
      } else {
        intervalId = setInterval(() => {
          setActiveIndex((prevIndex) => [prevIndex[0] + 1, DIRECTION.toLeft]);
        }, 4000);
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      clearInterval(intervalId);
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  const handleBuyMore = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <div className='main-wrapper'>
      <div className='pb-[4.875em]'>
        <h2 className='max-w-[100vw] font-semibold font-par-m text-[3.875em] whitespace-pre-wrap leading-[1.1612em] text-center tracking-[2%] text-[#000]'>
          {t('carousel.title')}
        </h2>

        <div className='flex justify-center mt-[1.5em]'>
          {isConnected && address ? (
            <div className='flex gap-2'>
              <Button
                className='h-[3.857em] w-[16.5714em] !bg-[#000] text-[#fff] !text-[1.3em]'
                variant='primary'
                onClick={handleBuyMore}
              >
                {t('carousel.buy')} $SOCS
              </Button>
            </div>
          ) : (
            <ConnectWalletDialog>
              <Button
                className='h-[3.857em] w-[16.5714em] !bg-[#000] text-[#fff] !text-[1.3em]'
                variant='primary'
              >
                {t('carousel.buy')} $SOCS
              </Button>
            </ConnectWalletDialog>
          )}
        </div>
      </div>

      <div className='wrapper'>
        <AnimatePresence mode='popLayout' initial={false}>
          {visibleItems.map((item, index) => (
            <motion.div
              data-elem={item.id}
              key={item.id}
              layout
              custom={{
                direction,
                position: () => {
                  if (item === visibleItems[carouselSetup.center]) {
                    return 'center';
                  }
                },
              }}
              variants={variants}
              initial='enter'
              animate='center'
              exit='exit'
              transition={{ duration: 2 }}
              style={{
                backgroundColor: item.color ? item.color : '',
              }}
            >
              {item.content}
            </motion.div>
          ))}
        </AnimatePresence>
      </div>
    </div>
  );
};

const variants = {
  enter: ({ direction }: { direction: number }) => {
    return {
      x: 350,
      opacity: 0,
      width: '20.625em',
      height: '38.9584em',
      borderRadius: '1.25em',
      fontSize: '1em',
      scale: 0.8,
    };
  },
  center: ({ position, direction }: { position: any; direction: number }) => {
    if (position() === 'center') {
      return {
        width: '20.625em',
        height: '38.9584em',
        borderRadius: '1.25em',
        fontSize: '1em',
        scale: 1,
        opacity: 1,
        x: 0,
      };
    } else {
      return {
        width: '20.625em',
        height: '38.9584em',
        borderRadius: '1.25em',
        fontSize: '1em',
        opacity: 1,
        scale: 0.8,
        x: 0,
      };
    }
  },
  exit: ({ direction }: { direction: number }) => {
    return {
      x: -350,
      opacity: 0,
    };
  },
};
