import { ReactNode } from 'react';
import { motion, Variants } from 'framer-motion';
import cn from 'classnames';

type Props = {
  parentWidth: number | undefined;
  index: number;
  width: number | undefined;
  transitionValue: number | undefined;
  color: string;
  styles?: string;
  children: ReactNode;
};

export const Card: React.FC<Props> = ({
  index,
  transitionValue,
  color,
  width,
  styles,
  children,
}) => {
  const cardVariants: Variants = {
    offscreen: {
      x: transitionValue,
    },
    onscreen: {
      x: 0,
      transition: {
        type: 'spring',
        bounce: 0.4,
        duration: 0.8,
      },
    },
  };

  return (
    <>
      {Boolean(transitionValue) && width && (
        <motion.div
          layout
          variants={cardVariants}
          className={cn(
            'group relative overflow-hidden flicking-element element group bg-no-repeat p-10 rounded-[2rem]',
            { styles }
          )}
          style={{
            backgroundColor: `${color}`,
            minHeight: `${width * 1.45}px`,
            height: `100%`,
          }}
        >
          <motion.div>{children}</motion.div>
        </motion.div>
      )}
    </>
  );
};
