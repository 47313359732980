import * as Progress from '@radix-ui/react-progress';
import { useEffect, useState } from 'react';

type Props = {
  value: number;
  color?: string;
};

export const ProgressBar: React.FC<Props> = ({
  value,
  color = '#6550BA',
}) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const timer = setTimeout(() => setProgress(value), 1000);
    return () => clearTimeout(timer);
  }, [value]);

  return (
    <Progress.Root
      className='relative overflow-hidden w-full h-[2em]'
      style={{
        backgroundColor: '#F5F5F5',
        // Fix overflow clipping in Safari
        // https://gist.github.com/domske/b66047671c780a238b51c51ffde8d3a0
        transform: 'translateZ(0)',
      }}
      value={progress}
    >
      <Progress.Indicator
        className='rounded-r-[10px] bg-white w-full h-full transition-transform duration-[1000ms] delay-0 ease-[cubic-bezier(0.65, 0, 0.35, 1)]'
        style={{
          backgroundColor: `${color}`,
          transform: `translateX(-${100 - progress}%)`,
        }}
      />
    </Progress.Root>
  );
};
