import {
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@radix-ui/react-accordion';
import { AccordionTriggerIcon } from 'ui-kit/icons/AccordionTriggerIcon';
import { ReactNode } from 'react';

import './style.css';

interface Props {
  title: string;
  value: string;
  children: ReactNode;
  color: string;
}

export function Accordion({ title, value, children, color }: Props) {
  return (
    <AccordionItem value={value}>
      <AccordionTrigger className='AccordionContent text-left flex justify-start gap-[1.3333em] items-center w-full font-medium leading-[1.75em] text-[1.125em] tracking-[0.02em]'>
        <AccordionTriggerIcon
          className='min-w-[1.7778em] min-h-[1.7778em] transition-all'
          fill={color}
        />
        <span className='text-[1.5556em] font-semibold font-par-m'>
          {title}
        </span>
      </AccordionTrigger>
      <AccordionContent className=' accordion-animation'>
        <div className=''>{children}</div>
      </AccordionContent>
    </AccordionItem>
  );
}
