import axios from "axios"

const API_KEY =
  process.env.REACT_APP_CLOUD_TRANSLATION_API ||
  "AIzaSyB9_4vq9jOu3kN8ixi88fSX0Ek7ImqcU3Y"
const API_URL = "https://translation.googleapis.com/language/translate/v2"

export const translateText = async (text: string, language: string) => {
  try {
    const response = await axios.post(`${API_URL}?key=${API_KEY}`, {
      q: text,
      target: language
    })

    return response.data.data.translations[0].translatedText
  } catch (error) {
    console.warn(error)
  }
}

export default translateText
