import { SVGAttributes } from "react";

export function TriangleDown({color ="white", ...props}: SVGAttributes<SVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23"
      height="24"
      viewBox="0 0 23 24"
      fill="none"
      {...props}
    >
      <path
        d="M11.5 15.7333L6.65029 10.1333L16.3498 10.1333L11.5 15.7333Z"
        fill={color}
      />
    </svg>
  );
}