import { motion, useWillChange } from 'framer-motion';
import { Button } from 'ui-kit/Button';
import { LogoWithText } from 'ui-kit/icons/LogoWithText';
import { formatAddress, scrollTo } from 'utils';
import { ConnectWalletDialog } from '../ConnectWalletDialog';
import { BalanceDialog } from '../BalanceDialog';
import { Logo } from 'ui-kit/icons/Logo';
import { useConnectedWalletIcon } from 'hooks/useConnectedWalletIcon';
import { TriangleDown } from 'ui-kit/icons/TriangleDown';
import { WalletPopover } from './components/WalletPopover';
import { useAccount } from 'wagmi';
import { Navigation } from './components/Navigation';
import { BurgerMenu } from './components/BurgerMenu';
import { useConnectModal } from '@rainbow-me/rainbowkit';
import { LocationSelector } from '../LocationSelector';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { locales } from 'constants/location';

export function Header() {
  const { isConnected, address } = useAccount();
  const walletIcon = useConnectedWalletIcon();
  const willChange = useWillChange();
  const { openConnectModal } = useConnectModal();
  const { i18n } = useTranslation();
  const [isOpenedOptions, setOpenedOptions] = useState(false);
  const { t } = useTranslation();
  const [location, setLocation] = useState(i18n.language);

  useEffect(() => {
    const savedLanguage = localStorage.getItem('language');
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage);
      setLocation(savedLanguage);
    } else {
      localStorage.setItem('language', i18n.language);
    }
  }, []);

  const handleSetLocation = (loc: string) => {
    i18n.changeLanguage(loc);
    setLocation(loc);
    localStorage.setItem('language', loc);
    setOpenedOptions(false);
  };

  return (
    <motion.header
      initial={{ opacity: 0, pointerEvents: 'none' }}
      animate={{ opacity: 1, pointerEvents: 'auto' }}
      transition={{ duration: 1, delay: 2 }}
      style={{ willChange }}
      className='font-formatDJR-m absolute left-0 right-0 top-0 grid grid-cols-[1fr_auto_1fr] lg:grid-cols-2 items-center pl-[3em] xl-1440:pr-[4.75em] md:!px-[2.25em] py-[1.25em] z-10 pr-[3em]'
    >
      <a href='/'>
        <LogoWithText className='w-[7.5em] h-[2.625em]' />
      </a>

      <Navigation place='header' />

      <div className='flex flex-nowrap justify-end relative gap-2'>
        <LocationSelector
          isOpenedOptions={isOpenedOptions}
          setOpenedOptions={setOpenedOptions}
          location={location}
          setLocation={handleSetLocation}
          locations={locales}
          type={'location'}
        />
        {isConnected && address ? (
          <div className='flex gap-2 justify-items-end justify-self-end'>
            <BalanceDialog>
              <Button className='h-[3.857em] text-[#000] px-7 gap-1 md:p-0 md:w-[3.857em]'>
                <Logo width={16} height={16} />
                <span className='md:hidden'>{t('header.balance')}</span>
              </Button>
            </BalanceDialog>
            <WalletPopover>
              <Button
                className='h-[3.857em] text-black px-7 gap-1 md:p-0 md:w-[3.857em]'
                variant='primary'
              >
                <img src={walletIcon} className='w-5 h-5' alt='' />
                <span className='md:hidden'>
                  {formatAddress(address, 4, 3)}
                </span>
                <TriangleDown
                  width={16}
                  height={16}
                  color='black'
                  className='md:hidden'
                />
              </Button>
            </WalletPopover>
          </div>
        ) : (
          <div className='flex gap-4 justify-items-end justify-self-end'>
            <Button
              onClick={openConnectModal}
              className='h-[3.857em] w-[14em] md:px-3 text-[#000]'
              variant='primary'
            >
              <span className=''>{t('header.connectWallet')}</span>
            </Button>
          </div>
        )}

        <BurgerMenu />
      </div>
    </motion.header>
  );
}
