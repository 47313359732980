import {
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@radix-ui/react-accordion";
import { AccordionTriggerIcon } from "ui-kit/icons/AccordionTriggerIcon";
import { ReactNode } from "react";

interface RoadmapAccordionProps {
  title: string;
  value: string;
  children: ReactNode;
}

export function RoadmapAccordion({
  title,
  value,
  children,
}: RoadmapAccordionProps) {
  return (
    <AccordionItem value={value}>
      <AccordionTrigger className="AccordionContent transition-all text-left flex justify-between items-center w-full font-medium text-[1.125em] leading-none tracking-[0.0225em]">
        {title}
        <AccordionTriggerIcon color={'black'} fill="#F7F7F7" className='min-w-[1.5em] min-h-[1.5em] transition-all'/>
      </AccordionTrigger>
      <AccordionContent className="max-w-[39.625em] font-medium text-[1em] leading-[1.625em] pr-[50px] text-[#959595] text-opacity-70 accordion-animation">
        <div className="pt-4">{children}</div>
      </AccordionContent>
    </AccordionItem>
  );
}
