import Flicking from '@egjs/react-flicking';
import { useEffect, useState } from 'react';

export const useFeatures = (flicking: React.MutableRefObject<Flicking | null>) => {
  const [parentSize, setParentSize] = useState(
    flicking.current?.viewport.width
  );
  const [panelWidth, setPanelWidth] = useState(flicking.current?.currentPanel.size);
  const [panels, setPanels] = useState(() => {
    if (window.outerWidth >= 1024) {
      return 3;
    }
    if (window.outerWidth >= 700 && window.outerWidth < 1024) {
      return 2;
    }
    if (window.outerWidth < 700) {
      return 1;
    }
  });

  const onSetParentWidth = () => {
    setParentSize(flicking.current?.viewport.width);
  };

  useEffect(() => {
    if (window.outerWidth >= 1024) {
      setPanels(3);
      setPanelWidth(flicking.current?.currentPanel.size)
    }
    if (window.outerWidth >= 700 && window.outerWidth < 1024) {
      setPanels(2);
      setPanelWidth(flicking.current?.currentPanel.size)
    }
    if (window.outerWidth < 700) {
      setPanels(1);
      setPanelWidth(flicking.current?.currentPanel.size)
    }

    window.addEventListener('resize', onSetParentWidth);

    return () => {
      window.removeEventListener('resize', onSetParentWidth);
    };
  }, [window.outerWidth, flicking.current?.viewport.width, flicking.current?.currentPanel.size]);

  useEffect(() => {
    if (!parentSize && flicking.current?.viewport.width) {
      setParentSize(flicking.current?.viewport.width);
    }
  }, []);

  return [parentSize, panelWidth, panels];
}
