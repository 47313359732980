import i18n from 'i18n'

export const faqMockData = () => [
  {
    title: i18n.t('faq.partOfPresale'),
    content: (
      <>
        {i18n.t('faq.followOur')}{' '}
        <a
          href={'https://desoc.gitbook.io/desoc_whitepaper/purchasing-usdsocs'}
          target='_blank'
          rel='noopener noreferrer'
          aria-label='desoc whitepaper'
          className='text-[#4959ff] hover:underline visited:text-[#8a24ff]'
        >
          {i18n.t('faq.purchasing')}
        </a>{' '}
        {i18n.t('faq.guide')}
      </>
    )
  },
  {
    title: i18n.t('faq.tokensDistributed'),
    content: (
      <>
        {i18n.t('faq.tokensDistributed.answer.firstPart')}{' '}
        <a
          href={
            'https://desoc.gitbook.io/desoc_whitepaper/usdsocs-tokenomics/vesting'
          }
          target='_blank'
          rel='noopener noreferrer'
          aria-label='desoc whitepaper'
          className='text-[#4959ff] hover:underline visited:text-[#8a24ff]'
        >
          {i18n.t('faq.tokensDistributed.answer.secondPart')}
        </a>
        .
      </>
    )
  },
  {
    title: i18n.t('faq.contractAddress'),
    content: i18n.t('faq.contractAddress.answer')
  },
  {
    title: i18n.t('faq.contactSupport'),
    content: (
      <>
        {i18n.t('faq.contactSupport.answer')}{' '}
        <a
          className='text-[#4959ff] hover:underline visited:text-[#8a24ff]'
          href={`mailto:help@desoc.space`}
        >
          help@desoc.space
        </a>
        .
      </>
    )
  },
  {
    title: i18n.t('faq.reward'),
    content: i18n.t('faq.reward.answer')
  },
  {
    title: i18n.t('faq.presaleStages'),
    content: i18n.t('faq.presaleStages.answer')
  }
]
