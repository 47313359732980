import i18n from 'i18n'

interface RoadmapData {
  color: string
  sectionName: string
  accordions: {
    title: string
    content: string
  }[]
}

export const roadmapMockData = (): RoadmapData[] => [
  {
    color: '#6d48a9',
    sectionName: i18n.t('featuresSocs.incentivizingContent'),
    accordions: [
      {
        title: i18n.t('featuresSocs.contentRewards'),
        content: i18n.t('featuresSocs.contentRewards.description')
      },
      {
        title: i18n.t('featuresSocs.tippingMicropayments'),
        content: i18n.t('featuresSocs.tippingMicropayments.description')
      },
      {
        title: i18n.t('featuresSocs.exclusiveAccess'),
        content: i18n.t('featuresSocs.exclusiveAccess.description')
      },
      {
        title: i18n.t('featuresSocs.contentMonetization'),
        content: i18n.t('featuresSocs.contentMonetization.description')
      }
    ]
  },
  {
    color: '#48a98c',
    sectionName: i18n.t('featuresSocs.exclusiveContent'),
    accordions: [
      {
        title: i18n.t('featuresSocs.contentLocking'),
        content: i18n.t('featuresSocs.contentLocking.description')
      },
      {
        title: i18n.t('featuresSocs.subscriptionModels'),
        content: i18n.t('featuresSocs.subscriptionModels.description')
      },
      {
        title: i18n.t('featuresSocs.rewardPrograms'),
        content: i18n.t('featuresSocs.rewardPrograms.description')
      }
    ]
  },
  {
    color: '#F19EFF',
    sectionName: i18n.t('featuresSocs.tokenizedGovernance'),
    accordions: [
      {
        title: i18n.t('featuresSocs.proposalSubmission'),
        content: i18n.t('featuresSocs.proposalSubmission.description')
      },
      {
        title: i18n.t('featuresSocs.governanceParameters'),
        content: i18n.t('featuresSocs.governanceParameters.description')
      },
      {
        title: i18n.t('featuresSocs.smartContracts'),
        content: i18n.t('featuresSocs.smartContracts.description')
      },
      {
        title: i18n.t('featuresSocs.quadraticVoting'),
        content: i18n.t('featuresSocs.quadraticVoting.description')
      }
    ]
  },
  {
    color: '#B9C8FF',
    sectionName: i18n.t('featuresSocs.microtransactions'),
    accordions: [
      {
        title: i18n.t('featuresSocs.tipping'),
        content: i18n.t('featuresSocs.tipping.description')
      },
      {
        title: i18n.t('featuresSocs.contentUnlocking'),
        content: i18n.t('featuresSocs.contentUnlocking.description')
      },
      {
        title: i18n.t('featuresSocs.purchases'),
        content: i18n.t('featuresSocs.purchases.description')
      },
      {
        title: i18n.t('featuresSocs.donationsSupport'),
        content: i18n.t('featuresSocs.donationsSupport.description')
      },
      {
        title: i18n.t('featuresSocs.premiumFeatures'),
        content: i18n.t('featuresSocs.premiumFeatures.description')
      },
      {
        title: i18n.t('featuresSocs.peerToPeer'),
        content: i18n.t('featuresSocs.peerToPeer.description')
      }
    ]
  },
  {
    color: '#5A546B',
    sectionName: i18n.t('featuresSocs.contentPromotion'),
    accordions: [
      {
        title: '',
        content: i18n.t('featuresSocs.contentPromotion.description')
      }
    ]
  }
]
