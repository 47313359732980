import { AxiosError } from 'axios';
import { TARGET_NETWORKS } from 'configurations/wagmi';
import { TOKEN_ADDRESS } from 'constants/vestingContract';
import { formatEther } from 'ethers/lib/utils';
import { useConnectedWalletIcon } from 'hooks/useConnectedWalletIcon';
import { useCopyToClipboard } from 'hooks/useCopyToClipboard';
import { useSigner } from 'hooks/useSigner';
import { ReactNode, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { tokensApi } from 'services/tokens';
import { tokensContractService } from 'services/tokensContract';
import { Dialog } from 'ui-kit/Dialog';
import { Copy } from 'ui-kit/icons/Copy';
import { Tick } from 'ui-kit/icons/Tick';
import { ClaimStatus, calcStatus, formatAddress } from 'utils';
import { useAccount, useBalance, useSwitchChain } from 'wagmi';
import DisplayClaim from './components/DisplayClaim';
import { useUserStore } from 'store/user';
import { authApi } from 'services/auth';
import { useTranslation } from 'react-i18next';

interface BalanceDialogProps {
  children: ReactNode;
}

export function BalanceDialog({ children }: BalanceDialogProps) {
  const [isOpen, setOpen] = useState(false);
  const [copiedText, copyToClipboard] = useCopyToClipboard(2000);
  const [copiedReferral, copyReferralToClipboard] = useCopyToClipboard(2000);
  const { address, chain } = useAccount();
  const walletIcon = useConnectedWalletIcon();
  const [balance, setBalance] = useState(0);
  const type = calcStatus();
  const [availableBalance, setAvailableBalance] = useState(0);
  const signer = useSigner();
  const [isClaiming, setIsClaiming] = useState(false);
  const user = useUserStore((s) => s.user);
	const { switchChainAsync } = useSwitchChain();
  const { t } = useTranslation()

  const { data: tokenBalance, refetch } = useBalance({
    address: address,
    chainId: TARGET_NETWORKS.id,
    token: TOKEN_ADDRESS as `0x${string}`,
  });

  const isFirstRender = useRef(true);

  const claimForm = useForm<{ value: number }>();

  useLayoutEffect(() => {
    if ((!isOpen && !isFirstRender.current) || !address || !signer) return;

    tokensApi.getTokensBalance().then(async (res) => {
      await authApi.getUser();
      await refetch();

      if (type === ClaimStatus.Claim) {
        const availableBalance = await tokensContractService.calculateClaimable(
          address,
          res,
          signer
        );

        const availableBalanceFormatted =
          Math.round(+formatEther(availableBalance) * 100) / 100;

        setAvailableBalance(availableBalanceFormatted);

        claimForm.setValue('value', availableBalanceFormatted);
      }

      setBalance(res);
    });

    return () => {
      isFirstRender.current = false;
    };
  }, [isOpen, tokenBalance?.formatted, address, signer, type]);

  const formattedTokenBalance = useMemo(
    () =>
      Math.ceil((balance - Number(tokenBalance?.formatted ?? 0)) * 100) / 100 ??
      0,
    [balance, tokenBalance?.formatted]
  );

  const handleWalletCopy = () => {
    if (address) copyToClipboard(address);
  };

  const handleReferralCopy = () => {
    if (user?.referralCode?.code)
      copyReferralToClipboard(user.referralCode.code);
  };

  const handleBuyMore = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleClaimSubmit = async () => {
    if (!signer || !chain || !switchChainAsync) return;

    try {
      setIsClaiming(true);

      if (chain.id !== TARGET_NETWORKS.id)
        await switchChainAsync({ chainId: TARGET_NETWORKS.id });

      await tokensContractService.claim(balance, signer);
      await tokensContractService.claim(balance, signer);

      handleOpenChange(false);
    } catch (error) {
      if (error instanceof AxiosError) {
        claimForm.setError('root', { message: error.response?.data?.message });
      } else if (error instanceof Error) {
        claimForm.setError('root', {
          message:
            error.message.length > 100 ? 'Something went wrong' : error.message,
        });
      }
    } finally {
      setIsClaiming(false);
    }
  };

  const handleOpenChange = (val: boolean) => {
    setOpen(val);

    if (!val) {
      claimForm.reset();
    }
  };

  const value = claimForm.watch('value');

  return (
    <Dialog trigger={children} open={isOpen} onOpenChange={handleOpenChange}>
      <div className='font-formatDJR-m flex flex-col gap-6 w-[517px] sm:w-[300px] p-8 pt-[3.125rem] max-h-[98vh] lg:overflow-y-scroll'>
        <div className='grid grid-cols-2 gap-[.5em] sm:grid-cols-1 md:gap-4'>
          <div className='flex flex-col gap-[.25em]'>
            <div className='font-medium text-xs leading-5 text-[#5e5e5e]'>
              {t('wallets.balanceDialog.wallet')}
            </div>

            <div className='w-full font-formatDJR-m flex justify-between items-center gap-4 p-4 rounded-xl bg-[#f2f2f2] font-[500] text-black text-sm leading-5'>
              <div className='flex gap-4 items-center'>
                <img src={walletIcon} className='w-5 h-5' alt='' />

                {address && formatAddress(address, 4, 3)}
              </div>

              <button
                disabled={!!copiedText}
                onClick={handleWalletCopy}
                className='justify-self-end'
              >
                <span className='flex justify-center items-center h-[1.75em] w-[1.75em] bg-white rounded-full'>
                  {!!copiedText ? (
                    <Tick color='#000' width={14} height={14} />
                  ) : (
                    <Copy width={14} height={14} />
                  )}
                </span>
              </button>
            </div>
          </div>

          {!!user?.referralCode && (
            <div className='flex flex-col gap-[.25em]'>
              <div className='font-medium text-xs leading-5 text-[#5e5e5e]'>
                {t('wallets.balanceDialog.referral')}
              </div>
              <div className='w-full font-formatDJR-m flex items-center justify-between gap-4 p-4 rounded-xl bg-[#f2f2f2] font-[500] text-black text-sm leading-5'>
                {user?.referralCode?.code}

                <button
                  disabled={!!copiedReferral}
                  onClick={handleReferralCopy}
                >
                  <span className='flex justify-center items-center h-[1.75em] w-[1.75em] bg-white rounded-full'>
                    {!!copiedReferral ? (
                      <Tick color='#000' width={14} height={14} />
                    ) : (
                      <Copy width={14} height={14} />
                    )}
                  </span>
                </button>
              </div>
            </div>
          )}
        </div>

        <DisplayClaim
          statusType={type}
          formattedTokenBalance={formattedTokenBalance}
          availableBalance={availableBalance}
          handleBuyMore={handleBuyMore}
          handleClaimSubmit={handleClaimSubmit}
          isClaiming={isClaiming}
          claimForm={claimForm}
          value={value}
        />
      </div>
    </Dialog>
  );
}
