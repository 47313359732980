import i18n from 'i18n'

export const tokenomicsMockData = () => [
  {
    label: 'tokenomics.presale',
    value: 1350000000,
    color: '#6550BA'
  },
  {
    label: 'tokenomics.platform',
    value: 600000000,
    color: '#FFCDBE',
    border: true
  },
  {
    label: 'tokenomics.marketing',
    value: 450000000,
    color: '#F3E5FF'
  },
  {
    label: 'tokenomics.community',
    value: 300000000,
    color: '#B9C8FF'
  },
  {
    label: 'tokenomics.liquidity',
    value: 300000000,
    color: '#B4FF55'
  }
]
