import { AnimatePresence, motion, useWillChange } from 'framer-motion';
import { formatUSD } from 'utils/number';
import { HeroForm } from './HeroForm';
import { Timer } from './Timer';
import Marquee from 'react-fast-marquee';
import { marqueeContentMap } from './marqueeContent';
import { Header } from '../Header';
import { Chart } from 'ui-kit/Chart';
import './styles.css';
import videoBg from '../../../../assets/backgrounds/bg-video-new.mp4';
import { useAppStore } from 'store';
import { Fragment } from 'react';
import poster from '../../../../assets/backgrounds/poster.webp';
import { useTranslation } from 'react-i18next';
import { Banner } from '../Banner';

export function Hero() {
  const [totalPool, sold, currentRound] = useAppStore((s) => [
    s.totalPool,
    s.sold,
    s.currentBonus,
    s.currentRound,
  ]);
  const willChange = useWillChange();
  const { t } = useTranslation();

  return (
    <>
      <Banner />

      <div
        className='relative flex min-h-screen pt-[7.5em] md:pt-[5em] pb-[2.625em] px-[4.75em] md:px-[2.25em] justify-between sm:justify-between text-[#fff] full-hd-bp:text-[1.33rem] lg:text-[0.6rem] sm:flex-col'
        id='hero'
      >
        <div className='video-background'>
          <video autoPlay muted playsInline poster={poster}>
            <source src={videoBg} type='video/mp4' />
          </video>
        </div>
        <Header />

        <motion.div
          initial={{ opacity: 0, pointerEvents: 'none' }}
          animate={{ opacity: 1, pointerEvents: 'auto' }}
          transition={{ duration: 2, delay: 1 }}
          className='grow relative flex flex-col items-start justify-start z-1 md:h-full sm:justify-center sm:items-center'
          style={{ willChange }}
        >
          {/* {currentRound && (
            <h2 className='text-3xl lg:text-xl text-center lg:w-[268px] w-[446px]'>
              {currentRound?.name}
            </h2>
          )} */}
          <Timer />

          <div className='flex flex-col md:h-full sm:justify-center'>
            <div className='mb-[1.5em]'>
              <h1 className='font-par-m text-[4.125em] sm:text-[4em] leading-[0.9756em] sm:max-w-[267px]'>
                {t('hero.title.firstPart')}
                <br /> {t('hero.title.secondPart')}
              </h1>
            </div>

            <HeroForm />
          </div>
        </motion.div>

        <AnimatePresence>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 2, delay: 2 }}
            style={{ willChange }}
            className='self-end relative lg:text-[0.6rem] sm:bottom-0 flex items-center flex-col gap-[1em]'
          >
            {currentRound && (
              <h2 className='text-3xl lg:text-xl text-center lg:w-[268px] w-[446px]'>
                {currentRound?.name}
              </h2>
            )}
            <Chart progress={(sold / totalPool) * 100}>
              <div className='flex flex-col items-center text-[#f5f1f4]'>
                <span className='font-articula-bold text-[1.5em] whitespace-nowrap'>
                  {`${formatUSD(Math.floor(sold))} SOCS`}
                </span>

                <span className='font-urbanist-medium text-center text-[1.125em] leading-5'>
                  {t('hero.sold')}
                </span>
              </div>
            </Chart>
          </motion.div>
        </AnimatePresence>
      </div>

      <Marquee
        play={true}
        className='marquee mt-[3.875em] mb-[3.45em] py-1 full-hd-bp:text-[1.33rem]'
      >
        {marqueeContentMap.map((el, i) => (
          <Fragment key={i}>
            {el.text ? (
              <div key={i} className='flex items-center gap-4'>
                <img
                  src={el.iconSrc}
                  className='w-[2.325em] h-[2.325em]'
                  alt=''
                />

                <span className='text-[1.25em] leading-snug font-medium text-[#000]'>
                  {el.text}
                </span>
              </div>
            ) : (
              <div key={i} className='flex items-center'>
                <img src={el.iconSrc} className='' alt='' />

                <span className='text-[1.25em] leading-snug font-medium text-[#000]'>
                  {el.text}
                </span>
              </div>
            )}
          </Fragment>
        ))}
      </Marquee>
    </>
  );
}
