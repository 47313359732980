import socs from 'assets/coins/socs.webp';
import { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { PaymentMethod, paymentMethods } from 'constants/methods';
import {
  MAX_AMOUNT_IN_USD_PER_WALLET,
  MIN_AMOUNT_IN_USD_PER_WALLET,
} from 'constants/sale';
import { Button } from 'ui-kit/Button';
import { ClaimStatus, calcAmountOfTokensToShow, calcStatus } from 'utils';
import { useAccount } from 'wagmi';
import { PaymentDialog } from '../PaymentDialogs';
import { ConnectWalletDialog } from '../ConnectWalletDialog';
import { EmailAddingDialog } from '../EmailAddingDialog';
import { useAppStore } from 'store';
import { useUserStore } from 'store/user';
import { PaymentSelect } from '../PaymentSelect';
import { useTranslation } from 'react-i18next';
import { tokensApi } from 'services/tokens';
import { IPaymentLimit } from 'types/Tokens';
import { Loader } from 'components/Loader';
import cn from 'classnames'

interface HeroFormType {
  value: string;
  method: PaymentMethod;
  paymentMethod: PaymentMethod;
}

export function HeroForm() {
  const [currentRound, currentBonus, currentReferralProgram] = useAppStore(
    (s) => [s.currentRound, s.currentBonus, s.currentReferralProgram]
  );
  const { address } = useAccount();
  const form = useForm<HeroFormType>({
    defaultValues: {
      value: '',
      paymentMethod: paymentMethods[0],
    },
  });
  const value = parseFloat(form.watch('value')?.replace(',', '.'));
  const paymentMethod = form.watch('paymentMethod');
  const youReceive = calcAmountOfTokensToShow(
    value,
    currentRound,
    currentBonus,
    false
  );
  const [isPaymentDialogOpen, setIsPaymentDialogOpen] = useState(false);
  const [isEmailAddingDialogOpen, setIsEmailAddingDialogOpen] = useState(false);
  const [paymentLimit, setPaymentLimit] = useState<IPaymentLimit | null>(null);
  const [isLoadingPaymentLimit, setIsLoadingPaymentLimit] = useState(false);
  const isSaleEnded = calcStatus() !== ClaimStatus.OngoingSale;
  const { isConnected } = useAccount();
  const { user } = useUserStore();
  const { email: userEmail } = user;
  const { t } = useTranslation();
  const minAmountInUsd = paymentLimit ? Math.ceil((paymentLimit?.usdPrice * paymentLimit?.minAmount + 0.02) * 100) / 100 : 0
  const isDisabledBuyButton = !value || value < 1 || minAmountInUsd > value || isPaymentDialogOpen || user.kicked || !currentRound;

  const onReturnToBy = () => {
    setIsEmailAddingDialogOpen(false);

    setIsPaymentDialogOpen(true);
  };

  const [submitBlockMessage, setSubmitBlockMessage] = useState('');

  const handleBuyNow = async () => {
    if (isSaleEnded) {
      setSubmitBlockMessage('The sale has ended');

      return;
    }

    if (!isConnected || !address) {
      setSubmitBlockMessage('Please connect your wallet first');
      return;
    }

    if (userEmail) {
      setIsPaymentDialogOpen(true);
    }

    if (!userEmail) {
      setIsEmailAddingDialogOpen(true);
    }
  };

  const handleEmailDialogOpen = (val: boolean) => {
    setIsEmailAddingDialogOpen(val);

    if (!val) form.reset();
  };

  const handlePaymentDialogOpen = (val: boolean) => {
    setIsPaymentDialogOpen(val);

    if (!val) form.reset();
  };

  const unactiveMethods = paymentMethods.filter(
    (el) => el.name !== paymentMethod.name
  );

  const onSetMethod = (method: PaymentMethod) => {
    form.setValue('paymentMethod', method);
  };

  const fetchPaymentLimit = useCallback(async (token: string) => {
    try {
      setIsLoadingPaymentLimit(true)
      const data = await tokensApi.getPaymentLimit(token);
      if (data) {
        setPaymentLimit(data)
      }
    } catch (error) {
      console.warn(error);
    } finally {
      setIsLoadingPaymentLimit(false)
    }
  }, []);

  useEffect(() => {
    if (value) {
      fetchPaymentLimit(paymentMethod.nowPaymentsId)
    } else {
      setPaymentLimit(null)
    }
  }, [paymentMethod.currency, value])

  return (
    <form
      onSubmit={form.handleSubmit(handleBuyNow)}
      className='flex flex-col w-[27.875em]'
    >
      <div className='flex mb-[.875em] items-center justify-between'>
        <div className='px-[.75em] py-[.5em] flex items-center justify-between gap-[.5em] rounded-full bg-white bg-transparent '>
          <img
            className='w-[1.5em] h-[1.5em]'
            src={paymentMethod.icon}
            alt={paymentMethod.name}
          />

          <span className='font-formatDJR-m font-medium text-[0.875em] text-black'>
            {paymentMethod.name}{' '}
            <span className='text-[#B2A7B0]'>
              ({paymentMethod.currency})
            </span>
          </span>
        </div>

        {unactiveMethods.slice(0, 5).map((el) => {
          return (
            <button type='button' onClick={() => onSetMethod(el)}>
              <img className='w-[2em] h-[2em]' src={el.icon} alt={el.name} />
            </button>
          );
        })}

        <Controller
          name='paymentMethod'
          control={form.control}
          render={({ field }) => (
            <PaymentSelect
              selected={field.value}
              onSelect={(val) => field.onChange(val)}
              type={'preselect'}
              filteredMethods={unactiveMethods.slice(5)}
            />
          )}
        />
      </div>

      <div className='flex flex-col gap-1 mb-[1.5em]'>
        <div className='grid grid-cols-2 gap-[.5em]'>
          <label className='font-formatDJR-m flex flex-col px-[1em] py-[1em] border-opacity-30 bg-white rounded-xl'>
            <span className='font-medium text-[0.875em] leading-4 text-[#8F8F8F] text-opacity-70'>
              {t('heroForm.pay')}
            </span>

            <div className='flex items-center gap-2'>
              <img
                src={paymentMethod.icon}
                alt=''
                className='w-[1.375em] h-[1.375em]'
              />
              <Controller
                name='value'
                control={form.control}
                disabled={isSaleEnded || !address}
                rules={{
                  required: 'This field is required',
                  min: {
                    value:
                      currentRound?.minAmount || MIN_AMOUNT_IN_USD_PER_WALLET,
                    message: `The amount must be greater than or equal to ${currentRound?.minAmount || MIN_AMOUNT_IN_USD_PER_WALLET
                      }$`,
                  },
                  max: {
                    value:
                      currentRound?.maxPurchasePerWallet ||
                      MAX_AMOUNT_IN_USD_PER_WALLET,
                    message: `The amount must be less than or equal to ${currentRound?.maxPurchasePerWallet ||
                      MAX_AMOUNT_IN_USD_PER_WALLET
                      }$`,
                  },
                }}
                render={({ field }) => (
                  <div className={cn('relative', value && 'before:content-["$"] before:absolute before:top-[50%] before:left-0 text-black before:translate-y-[-50%]')}>
                    <input
                      type='text'
                      className={cn('hero-form-field max-w-[160px] bg-transparent outline-none font-medium text-[1.125em] leading-normal text-black placeholder:text-black', value && 'pl-[10px]')}
                      placeholder={`0.00 USD`}
                      disabled={isSaleEnded || !address}
                      {...field}
                      onChange={(e) => {
                        const amount = e.target.value;

                        if (!amount || amount.match(/^\d{1,}([.,]{1}\d{0,4})?$/)) {
                          field.onChange(e);
                        }
                      }}
                    />
                  </div>
                )}
              />
            </div>
          </label>

          <label className='font-formatDJR-m flex flex-col px-[1em] py-[1em] border-opacity-30 bg-white rounded-xl'>
            <span className='font-medium text-[0.875em] leading-4 text-[#8F8F8F] text-opacity-70'>
              {t('heroForm.receive')}
            </span>

            <div className='flex items-center gap-2'>
              <img src={socs} alt='' className='w-5 h-5' />

              <input
                type='text'
                className='hero-form-field max-w-[160px] bg-transparent outline-none font-medium text-[1.125em] leading-normal text-black placeholder:text-black'
                readOnly
                disabled
                value={`${youReceive || 0} $SOCS`}
              />
            </div>
          </label>
        </div>
        {paymentLimit && <p className='text-[20px] font-formatDJR-b'>{t('minimumAmountPay')}: {minAmountInUsd} USD</p>}
      </div>
      

      {form.formState.errors.value && (
        <div className='font-bold mb-3 -mt-3'>
          {form.formState.errors.value.message}
        </div>
      )}
      {submitBlockMessage && (
        <div className='font-bold mb-3 -mt-3'>{submitBlockMessage}</div>
      )}

      {!!currentBonus && (
        <div className='font-formatDJR-m backdrop-blur-[5px] flex gap-[.5em] px-[1em] py-[1em] bg-white bg-opacity-10 rounded-xl mb-[1.5em]'>
          <span className='font-[500] text-[1em] leading-1.3em text-white'>
            {currentBonus?.description}
          </span>
        </div>
      )}

      {currentReferralProgram?.enabled && (
        <div className='font-formatDJR-m backdrop-blur-[5px] flex gap-[.5em] px-[1em] py-[1em] bg-white bg-opacity-10 rounded-xl mb-[1.5em]'>
          <span className='font-[500] text-[1em] leading-1.3em text-white'>
            {currentReferralProgram?.description}
          </span>
        </div>
      )}

      {isConnected && address ? (
        <Button
          className='h-[3.857em] text-[#000] font-formatDJR-m cursor-pointer transition-colors duration-300 disabled:opacity-50 disabled:pointer-events-none'
          variant='primary'
          disabled={isDisabledBuyButton}
        >
          {isLoadingPaymentLimit ? <Loader /> : <>{t('heroForm.buy')} $SOCS</>}
        </Button>
      ) : (
        <ConnectWalletDialog>
          <Button
            className='h-[3.857em] text-[#000] font-formatDJR-m cursor-pointer'
            variant='primary'
          >
            {t('heroForm.buy')} $SOCS
          </Button>
        </ConnectWalletDialog>
      )}

      <PaymentDialog
        open={isPaymentDialogOpen}
        onOpenChange={handlePaymentDialogOpen}
        paymentInUSD={value}
        paymentMethod={paymentMethod}
      />

      <EmailAddingDialog
        isOpen={isEmailAddingDialogOpen}
        onOpenChange={handleEmailDialogOpen}
        onReturnToBy={onReturnToBy}
      />
    </form>
  );
}
