import { useCopyToClipboard } from 'hooks/useCopyToClipboard';
import { useFormContext } from 'react-hook-form';
import { PaymentProgress } from 'ui-kit/PaymentProgress';
import { Copy } from 'ui-kit/icons/Copy';
import { Tick } from 'ui-kit/icons/Tick';
import { formatAddress } from 'utils';
import { PaymentFormType, Step } from './scheme';
import { Button } from 'ui-kit/Button';
import { RightArrowWithTail } from 'ui-kit/icons/RightArrowWithTail';
import { QRCodeSVG } from 'qrcode.react';
import { useEffect } from 'react';
import { tokensApi } from 'services/tokens';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

interface SendDepositProps {
  paymentInUSD: number;
}

export function SendDeposit({ paymentInUSD }: SendDepositProps) {
  const form = useFormContext<PaymentFormType>();
  const { t } = useTranslation()

  if (!form)
    throw new Error('This component must be used within a <FormProvider />');

  const orderId = form.watch('orderId');
  const paymentMethod = form.watch('paymentMethod');
  const paymentStatus = form.watch('paymentStatus');
  const paymentInAsset = form.watch('payAmount');
  const address = form.watch('payAddress');

  const [copiedText, copyToClipboard] = useCopyToClipboard(2000);
  const handleCopy = () => {
    copyToClipboard(address);
  };

  useEffect(() => {
    if (paymentStatus === 'PAID') return;

    const interval = setTimeout(async () => {
      if (!orderId) return;
      try {
        const order = await tokensApi.getOrderById(orderId);

        if (!order) return;

        if (order.status === 'EXPIRED') {
          form.setValue('step', Step.Error);
          return;
        }

        form.setValue('paymentStatus', order.status);
      } catch (error) {
        form.setValue('step', Step.Error);
      }
    }, 3000);

    return () => clearTimeout(interval);
  }, [orderId, form, paymentStatus]);

  const handleSendDeposit = () => {
    if (paymentStatus === 'PAID') {
      form.setValue('step', Step.Success);
    }
  };

  return (
    <>
      <div className='flex flex-col items-center gap-2'>
        <div className='font-formatDJR-m my-auto px-3 py-1.5 rounded-lg bg-[#f2f2f2] font-medium text-xs leading-5 text-[#8f8f8f]'>
          2 {t('of')} 2 {t('steps')}
        </div>
        <h2 className='font-formatDJR-m font-medium text-2xl leading-snug text-black'>
          {t('sendDeposit.title')}
        </h2>
      </div>
      <div className='flex flex-col gap-4'>
        <div className='flex items-start gap-6 sm:flex-col sm:items-center'>
          <QRCodeSVG value={address} width={140} height={140} />

          <div className='flex flex-col gap-4 sm:w-full'>
            <div className='font-formatDJR-m flex flex-col gap-2 font-medium text-xs text-[#978D95]'>
              <span>{t('sendDeposit.asset')}</span>

              <div className='flex items-center gap-2'>
                <img
                  src={paymentMethod.icon}
                  className='w-6 h-6'
                  alt={paymentMethod.name}
                />
                <span className='font-[500] text-sm leading-5 text-black'>
                  {paymentInAsset} {paymentMethod.currency}
                </span>

                <span>~ ${paymentInUSD || 0}</span>
              </div>
            </div>

            <div className='font-formatDJR-m flex flex-col gap-2 font-medium text-xs text-[#978D95] sm:w-full'>
              <span>{t('sendDeposit.address')}</span>

              <div className='flex items-center gap-2 sm:w-full sm:justify-between'>
                <span className='w-[150px] font-[500] text-sm leading-5 text-black'>
                  {formatAddress(address, 4, 3)}
                </span>

                <button disabled={!!copiedText} onClick={handleCopy}>
                  {!!copiedText ? <Tick color='#000' /> : <Copy />}
                </button>
              </div>
            </div>
          </div>
        </div>

        <PaymentProgress status={paymentStatus} />
      </div>

      <Button
        className={cn(
          'font-formatDJR-m font-[500] h-[3.375rem] text-white',
          { '!bg-black': paymentStatus === 'PAID' },
          { '!bg-[#8a8a8a]': paymentStatus !== 'PAID' }
        )}
        variant='primary'
        onClick={handleSendDeposit}
        disabled={paymentStatus !== 'PAID'}
      >
        {t('button.nextStep')}
        <RightArrowWithTail />
      </Button>
    </>
  );
}
