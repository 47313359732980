import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Button } from 'ui-kit/Button';
import { Info } from 'ui-kit/icons/Info';

import errorIcon from '../../../../assets/vectors/form-error-circle.svg';
import { AxiosError } from 'axios';
import { PaymentFormType, Step } from './scheme';
import { errorMessageHandler } from 'utils/errorMessages';
import { tokensApi } from 'services/tokens';
import { useTranslation } from 'react-i18next';

export const Referral = () => {
  const [isSending, setIsSending] = useState(false);
  const form = useFormContext<PaymentFormType>();
  const { t } = useTranslation()

  const errorMessage = form.formState.errors.referral?.message;
  const referral = form.watch('referral');

  const handleCleanReferral = () => {
    form.setValue('referral', '');
  };

  const handleSendReferral = async () => {
    if (!referral) return;
    form.clearErrors();

    try {
      setIsSending(true);

      const isValid = await tokensApi.isRefCodeValid(referral);

      if (!isValid) {
        throw new Error('Invalid referral code');
      }

      form.setValue('step', Step.SelectAsset);
    } catch (error) {
      if (error instanceof AxiosError) {
        const message = errorMessageHandler(error.response?.data.message);

        form.setError('referral', { message });
      } else {
        form.setError('referral', { message: (error as Error).message });
      }
      console.warn(error);
    } finally {
      setIsSending(false);
    }
  };

  const handleSkipReferral = () => {
    form.setValue('step', Step.SelectAsset);
  };

  useEffect(() => {
		if (errorMessage) {
			setTimeout(() => {
				form.setError('referral', { message: '' });
			}, 3000)
		}
	}, [errorMessage]);

  return (
    <>
      <h2 className='font-formatDJR-b text-[1.75rem] leading-snug text-center text-black'>
        {t('enterRefCode')}
      </h2>

      <div className='flex flex-col font-formatDJR-b'>
        <label
          htmlFor='referral'
          className='font-medium text-xs leading-5 text-[#5e5e5e]'
        >
          {t('refCode')}
        </label>

        <label className='flex items-center justify-between p-3.5 rounded-lg bg-[#F2F2F2] text-[#000] cursor-text'>
          <input
            type='text'
            className='grow bg-transparent font-medium text-base leading-snug outline-none hero-form-field placeholder:text-[#7d7d7d]'
            placeholder={t('refCode')}
            id='referral'
            defaultValue={referral}
            {...form.register('referral')}
          />

          {referral && (
            <img
              src={errorIcon}
              alt='status'
              onClick={handleCleanReferral}
              className='cursor-pointer'
            />
          )}
        </label>

        {errorMessage && (
          <div className='flex items-center gap-2.5 p-[12px] mt-[1.5rem] rounded-lg bg-[#FFE7EA] font-[400] text-xs leading-[0.875rem] text-[#92304D]'>
            <Info className='min-w-4' stroke='#92304D' />

            <span>{errorMessage}</span>
          </div>
        )}

        <Button
          type='submit'
          variant='primary'
          disabled={isSending}
          onClick={handleSendReferral}
          className='!font-[500] h-[3.375rem] mt-[1rem] text-white !bg-black gap-[10px]'
        >
          {t('button.continue')}
        </Button>

        <Button
          type='button'
          variant='primary'
          disabled={isSending}
          className='font-formatDJR-m !font-[500] mt-[1rem] text-black w-fit h-fit mx-auto !p-0'
          onClick={handleSkipReferral}
        >
          {t('button.skip')}
        </Button>
      </div>
    </>
  );
};
