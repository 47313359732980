import { ReactNode, useEffect, useState } from 'react';
import { Dialog } from 'ui-kit/Dialog';
import { useAccount } from 'wagmi';
import { WalletButtons } from './components/WalletButtons';
import { useTranslation } from 'react-i18next';

interface ConnectWalletDialogProps {
  children?: ReactNode;
}

export function ConnectWalletDialog({ children }: ConnectWalletDialogProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [isLocked, setIsLocked] = useState(false);
  const { isConnected } = useAccount();
  const { t } = useTranslation()

  useEffect(() => {
    if (isConnected) {
      setIsOpen(false);
    }
  }, [isConnected]);

  return (
    <Dialog
      open={isOpen}
      onOpenChange={(val) => setIsOpen(val)}
      trigger={children}
      contentProps={{
        onInteractOutside: (e) => !isLocked && e.preventDefault(),
      }}
      modal={false}
    >
      <div className='flex flex-col gap-6 w-screen max-w-[517px] sm:w-[300px] p-10 pt-[3.125rem]'>
        <h2 className='font-medium text-[1.75rem] leading-snug text-center text-black'>
          {t('wallets.select')}
        </h2>
        <div className='grid grid-cols-2 gap-2 sm:grid-cols-1'>
          <WalletButtons />
        </div>
      </div>
    </Dialog>
  );
}
