import { useEffect, useLayoutEffect, useState } from 'react';

type UseLockedBodyOutput = [boolean, (locked: boolean) => void];

export const useIsomorphicLayoutEffect =
  typeof window !== 'undefined' ? useLayoutEffect : useEffect;

function getScrollBarWidth() {
  let el = document.createElement('div');
  el.style.cssText = 'overflow:scroll; visibility:hidden; position:absolute;';
  document.body.appendChild(el);
  let width = el.offsetWidth - el.clientWidth;
  el.remove();
  return width;
}

export function useLockedScroll(
  initialLocked = false,
  rootId = '___gatsby' // Default to `___gatsby` to not introduce breaking change
): UseLockedBodyOutput {
  const [locked, setLocked] = useState(initialLocked);

  // Do the side effect before render
  useIsomorphicLayoutEffect(() => {
    if (!locked) {
      return;
    }
    const root = document.getElementById(rootId) || document.body; // or root

    // Save initial body style
    const originalOverflow = root.style.overflow;
    const originalPaddingRight = getComputedStyle(root).paddingRight;

    // Get the scrollBar width
    const scrollBarWidth = getScrollBarWidth() + parseInt(originalPaddingRight);

    // Lock body scroll
    root.style.overflow = 'hidden';

    // Avoid width reflow
    if (scrollBarWidth) {
      root.style.paddingRight = `${scrollBarWidth}px`;
    }

    return () => {
      root.style.overflow = originalOverflow;

      if (scrollBarWidth) {
        root.style.paddingRight = originalPaddingRight;
      }
    };
  }, [locked]);

  // Update state if initialValue changes
  useEffect(() => {
    if (locked !== initialLocked) {
      setLocked(initialLocked);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialLocked]);

  return [locked, setLocked];
}
