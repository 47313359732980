import { ReactNode } from "react";
import i18next from "i18next";

type Data = {
  color: string;
  id: number;
  content: ReactNode;
  styles?: string;
};

export const experienceMockData = (): Data[] => [
  {
    color: "#E3FFB5",
    id: 1,
    content: (
      <>
        <h3 className='text-black font-par-m text-[1.75em] font-[600] leading-[2.25rem] full-hd-bp:leading-[3.25rem] mb-4'>
          {i18next.t("carouselData.advertisementPartnerships.title")}
        </h3>
        <ul className='text-[#5E5E5E] tracking-[.02em] leading-[1.25em] list-disc [&>li:not(:last-child)]:mb-4'>
          <li>
            {i18next.t(
              "carouselData.advertisementPartnerships.description.firstPart"
            )}
          </li>
          <li>
            {i18next.t(
              "carouselData.advertisementPartnerships.description.secondPart"
            )}
          </li>
        </ul>
      </>
    ),
  },
  {
    color: "#fff",
    id: 2,
    content: (
      <>
        <h3 className='text-black font-par-m text-[1.75em] font-[600] leading-[2.25rem] full-hd-bp:leading-[3.25rem] mb-4'>
          {i18next.t("carouselData.verifiabilityThrough.title")}
        </h3>
        <ul className='text-[#5E5E5E] tracking-[.02em] leading-[1.25em] list-disc [&>li:not(:last-child)]:mb-4'>
          <li>
            {i18next.t("carouselData.verifiabilityThrough.records")}{" "}
            {i18next.t("carouselData.verifiabilityThrough.records.description")}
          </li>
          <li>
            {i18next.t("carouselData.verifiabilityThrough.smartContracts")}{" "}
            {i18next.t(
              "carouselData.verifiabilityThrough.smartContracts.description"
            )}
          </li>
          <li>
            {i18next.t("carouselData.verifiabilityThrough.auditableData")}{" "}
            {i18next.t(
              "carouselData.verifiabilityThrough.auditableData.description"
            )}
          </li>
        </ul>
      </>
    ),
  },
  {
    color: "#EDE2FF",
    id: 3,
    content: (
      <>
        <h3 className='text-black font-par-m text-[1.75em] font-[600] leading-[2.25rem] full-hd-bp:leading-[3.25rem] mb-4'>
          {i18next.t("carouselData.incentivizedParticipation.title")}
        </h3>
        <ul className='text-[#5E5E5E] tracking-[.02em] leading-[1.25em] list-disc [&>li:not(:last-child)]:mb-4'>
          <li>
            {i18next.t(
              "carouselData.incentivizedParticipation.description.firstPart"
            )}
          </li>
          <li>
            {i18next.t(
              "carouselData.incentivizedParticipation.description.secondPart"
            )}
          </li>
        </ul>
      </>
    ),
  },
];
