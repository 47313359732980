import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import { Buffer } from 'buffer'
import './i18n'

window.global = window.global ?? window
window.Buffer = window.Buffer ?? Buffer
window.process = window.process ?? { env: {} } // Minimal process polyfill

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)
