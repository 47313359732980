import {
  VESTING_CONTRACT_ADDRESS,
  vestingContractAbi,
} from 'constants/vestingContract';
import { ethers } from 'ethers';
import { parseEther } from 'ethers/lib/utils';
import { tokensApi } from './tokens';

export class TokensContractService {
  /**
   * @throws {Error | AxiosError} This method may throw an error
   */
  async claim(amountToClaim: number, signer: ethers.Signer) {
    try {
      const markleProof = await tokensApi.getMarkleTreeProof();

      const contract = new ethers.Contract(
        VESTING_CONTRACT_ADDRESS,
        vestingContractAbi,
        signer
      );

      const tx = await contract.claim(
        parseEther(amountToClaim.toString()),
        markleProof
      );

      await tx.wait();
    } catch (error: any) {
      if (error.code === ethers.errors.ACTION_REJECTED) {
        return;
      }

      throw error;
    }
  }

  async calculateClaimable(
    address: `0x${string}`,
    totalBalance: number,
    signer: ethers.Signer
  ) {
    try {
      const contract = new ethers.Contract(
        VESTING_CONTRACT_ADDRESS,
        vestingContractAbi,
        signer
      );

      const res = await contract.calculateClaimable(
        address,
        parseEther(totalBalance.toString())
      );

      return res;

    } catch (error: any) {
      if (error.code === ethers.errors.ACTION_REJECTED) {
        return;
      }
      console.warn(error);
    }
    return 0;
  }
}

export const tokensContractService = new TokensContractService();
