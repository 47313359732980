import { InputHTMLAttributes, forwardRef } from 'react';
import './styles.css';

export const FormTextArea = forwardRef<
  HTMLTextAreaElement,
  InputHTMLAttributes<HTMLTextAreaElement>
>((props, ref) => {
  return (
    <textarea
      className='autofill-fix text-[1em] w-full h-[7.625em] pl-6 pr-1 py-4 bg-transparent bg-opacity-5 outline-none font-[400] resize-none placeholder:text-white'
      ref={ref}
      {...props}
    />
  );
});
