import { AxiosError } from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { tokensApi } from 'services/tokens';
import { useAppStore } from 'store';
import { Button } from 'ui-kit/Button';
import { RightArrowWithTail } from 'ui-kit/icons/RightArrowWithTail';
import { calcAmountOfTokens, calcAmountOfTokensToShow } from 'utils';
import { PaymentSelect } from '../PaymentSelect';
import { PaymentFormType, Step } from './scheme';
import { PaymentMethod } from 'constants/methods';
import { IPaymentLimit } from 'types/Tokens';
import { useTranslation } from 'react-i18next';

interface SelectAssetProps {
  paymentInUSD: number;
  preselectedMethod: PaymentMethod;
}

export function SelectAsset({
  paymentInUSD,
  preselectedMethod,
}: SelectAssetProps) {
  const form = useFormContext<PaymentFormType>();
  const [isLoading, setIsLoading] = useState(false);
  const [isLimitLoading, setIsLimitLoading] = useState(false);
  const [paymentLimit, setPaymentLimit] = useState<IPaymentLimit | null>(null);
  const [currentRound, currentBonus, settings] = useAppStore((s) => [
    s.currentRound,
    s.currentBonus,
    s.settings,
  ]);
  const { t } = useTranslation()

  if (!form) throw new Error('SelectAsset must be used within a FormProvider');

  const paymentMethod = form.watch('paymentMethod');
  const referral = form.watch('referral');

  const fetchMinLimit = useCallback(async (token: string) => {
    try {
      setIsLimitLoading(true);
      const data = await tokensApi.getPaymentLimit(token);
      setPaymentLimit(data);
    } catch (error) {
      console.warn(error);
    } finally {
      setIsLimitLoading(false);
    }
  }, []);

  useEffect(() => {
    if (preselectedMethod) form.setValue('paymentMethod', preselectedMethod);
    fetchMinLimit(preselectedMethod.nowPaymentsId);
  }, [preselectedMethod]);

  const handleSelectAsset = async () => {
    const tokens = calcAmountOfTokens(
      paymentInUSD,
      currentRound?.tokenPrice,
      currentRound?.maxPurchasePerWallet
    );
    const { paymentMethod } = form.getValues();
    setIsLoading(true);
    try {
      const res = await tokensApi.sendOrder(
        tokens,
        paymentMethod.nowPaymentsId,
        referral
      );

      if (!res) {
        throw new Error('Something went wrong');
      }

      form.setValue('payAmount', res.invoice.payAmount);
      form.setValue('payAddress', res.invoice.payAddress);
      form.setValue('orderId', res.order.id);

      form.setValue('step', Step.SendDeposit);
    } catch (error) {
      if (error instanceof AxiosError) {
        form.setError('root', {
          type: 'custom',
          message: error.response?.data.message,
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className='flex flex-col items-center gap-2'>
        <div className='font-formatDJR-m my-auto px-3 py-1.5 rounded-lg bg-[#f2f2f2] font-medium text-xs leading-5 text-[#8f8f8f]'>
          1 {t('of')} 2 {t('steps')}
        </div>
        <h2 className='font-formatDJR-m font-medium text-2xl leading-snug text-[#000]'>
          {t('selectAsset.title')}
        </h2>
      </div>
      {settings?.nowPaymentsLimitMessageEnabled && (
        <div className='flex items-center gap-2.5 p-[12px] rounded-lg bg-[#f2f2f2] font-[400] text-xs leading-[0.875rem] text-[#5e5e5e]'>
          {isLimitLoading && t('selectAsset.limitsLoading')}
          {!isLimitLoading &&
            `${t('minimumAmountPay')}: ${paymentLimit && paymentLimit.minAmount} ${paymentMethod.currency}`}
        </div>
      )}
      {settings?.nowPaymentsLimitMessageEnabled && (
        <div className='flex items-center gap-2.5 p-[12px] rounded-lg bg-[#f2f2f2] font-[400] text-xs leading-[0.875rem] text-[#5e5e5e]'>
          {isLimitLoading && t('selectAsset.limitsLoading')}
          {!isLimitLoading &&
            `${t('minimumAmountPay')}: ${paymentLimit && (Math.ceil((paymentLimit?.usdPrice * paymentLimit?.minAmount + 0.02) * 100) / 100)} USD`}
        </div>
      )}
      <div className='flex flex-col gap-2'>
        <label className='font-formatDJR-m font-medium text-xs leading-5 text-[#978D95]'>
          {t('selectAsset.receive')}
        </label>
        <div className='flex justify-between w-full p-4 rounded-xl bg-[#F2F2F2] text-black'>
          <div className='font-formatDJR-m flex items-center gap-2 font-bold text-sm leading-5 text-black'>
            {calcAmountOfTokensToShow(
              paymentInUSD,
              currentRound,
              currentBonus,
              referral?.length > 0
            )}{' '}
            $SOCS
          </div>
        </div>
        {form.formState.errors.root && (
          <div className='text-red-500'>
            {form.formState.errors.root.message}
          </div>
        )}
      </div>
      <div className='flex flex-col gap-2'>
        <label className='font-formatDJR-m font-medium text-xs leading-5 text-[#978D95]'>
          {t('selectAsset.label')}
        </label>
        <Controller
          name='paymentMethod'
          control={form.control}
          render={({ field }) => (
            <PaymentSelect
              selected={field.value}
              onSelect={(val) => field.onChange(val)}
            />
          )}
        />
        {form.formState.errors.root && (
          <div className='text-red-500'>
            {form.formState.errors.root.message}
          </div>
        )}
      </div>
      <Button
        className='font-formatDJR-m font-[500] h-[3.375rem] !bg-black text-white'
        variant='primary'
        onClick={handleSelectAsset}
        disabled={isLoading}
      >
        {isLoading ? (
          t('selectAsset.loading')
        ) : (
          <>
            {t('button.nextStep')}
            <RightArrowWithTail />
          </>
        )}
      </Button>
    </>
  );
}
