import clsx from 'clsx';
import { ButtonHTMLAttributes, ReactNode, forwardRef } from 'react';
import { variants } from './variants';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children?: ReactNode;
  variant?: keyof typeof variants;
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ children, className, variant = 'DEFAULT', ...props }, ref) => {
    return (
      <button
        className={clsx(
          ' bg-white flex justify-center items-center py-3 full-hd-bp:py-4 rounded-full font-bold text-[0.875em] full-hd-bp:text-[1em] leading-4',
          // variants[variant],
          className
        )}
        ref={ref}
        {...props}
      >
        {children}
      </button>
    );
  }
);
