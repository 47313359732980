import { DialogClose } from '@radix-ui/react-dialog';
import SuccessIcon from 'assets/success.webp';
import { useTranslation } from 'react-i18next';
import { Button } from 'ui-kit/Button';

export function SuccessResult() {
  const { t } = useTranslation()
  return (
    <>
      <div className="flex justify-center">
        <img
          src={SuccessIcon}
          className="w-[69px] h-[79px]"
          alt="Success icon"
        />
      </div>
      <div className="font-formatDJR-m flex flex-col items-center gap-2">
        <h2 className="font-[500] text-2xl leading-snug text-[#000]">
          {t('successResult.title')}
        </h2>
      </div>
      <DialogClose asChild>
        <Button className="font-formatDJR-m font-[500] h-[3.375rem] text-white !bg-black" variant="primary">
          {t('successResult.buttonBack')}
        </Button>
      </DialogClose>
    </>
  );
}
