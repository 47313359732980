import { FooterBlockContainer } from '../FooterBlockContainer';
import iconX from '../../../../../assets/vectors/social-x.svg';
import iconGitBook from '../../../../../assets/vectors/social-gitbook.svg';
import iconTelegtam from '../../../../../assets/vectors/social-telegram.svg';
import { LogoWithText } from 'ui-kit/icons/LogoWithText';
import { gitbook, telegram, twitter } from 'constants/links';

type Props = {
  styles: string;
};

export const Socials: React.FC<Props> = ({ styles }) => {
  return (
    <FooterBlockContainer
      title=''
      classNames={styles}
      logo={
        <a href='/'>
          <LogoWithText className='w-[10.875em] h-[2.875em]' />
        </a>
      }
    >
      <div className='flex flex-row justify-between gap-[1em]'>
        <a
          href={twitter}
          target='_blank'
          rel='noopener noreferrer'
          aria-label='X'
          className='py-[0.875em] w-[3em] flex justify-center h-[3em] border-[.5px] border-gray-500 rounded-full'
        >
          <img src={iconX} alt='Social X' />
        </a>
        <a
          href={gitbook}
          target='_blank'
          rel='noopener noreferrer'
          aria-label='GitBook'
          className='py-[0.875em] w-[3em] flex justify-center h-[3em] border-[.5px] border-gray-500 rounded-full'
        >
          <img src={iconGitBook} alt='Social X' className='' />
        </a>

        <a
          href={telegram}
          target='_blank'
          rel='noopener noreferrer'
          aria-label='Telegram'
          className='py-[0.875em] w-[3em] flex justify-center h-[3em] border-[.5px] border-gray-500 rounded-full'
        >
          <img src={iconTelegtam} alt='Social Telegram' />
        </a>
      </div>
    </FooterBlockContainer>
  );
};
