import { SVGAttributes } from 'react';

export function Logo(props: SVGAttributes<SVGElement>) {
  return (
    <svg
      width='28'
      height='28'
      viewBox='0 0 28 28'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <circle
        cx='14'
        cy='14'
        r='13.75'
        fill='url(#paint0_linear_750_7432)'
        stroke='white'
        strokeWidth='0.5'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.2339 9.34963C11.2339 10.0525 10.6641 10.6224 9.9612 10.6224C9.2583 10.6224 8.68848 10.0525 8.68848 9.34963C8.68848 8.64672 9.2583 8.0769 9.9612 8.0769C10.6641 8.0769 11.2339 8.64672 11.2339 9.34963ZM17.1797 12.1137C16.2571 12.1137 15.4237 12.4944 14.8276 13.1071L14.033 13.9239L14.8921 14.8068L15.7102 13.9657C16.0829 13.5826 16.6041 13.3442 17.1797 13.3442C18.3104 13.3442 19.2305 14.2643 19.2305 15.3953C19.2305 16.5259 18.3104 17.446 17.1797 17.446C16.6041 17.446 16.0829 17.2076 15.7102 16.8242L12.0943 13.1071C11.4982 12.4944 10.6648 12.1137 9.74219 12.1137C7.93012 12.1137 6.46094 13.5829 6.46094 15.3953C6.46094 17.2073 7.93012 18.6765 9.74219 18.6765C10.6648 18.6765 11.4982 18.2959 12.0943 17.6831L12.8889 16.8664L12.0298 15.9834L11.2116 16.8242C10.8389 17.2076 10.3178 17.446 9.74219 17.446C8.61152 17.446 7.69141 16.5259 7.69141 15.3953C7.69141 14.2643 8.61152 13.3442 9.74219 13.3442C10.3178 13.3442 10.8389 13.5826 11.2116 13.9657L14.8276 17.6831C15.4237 18.2959 16.2571 18.6765 17.1797 18.6765C18.9918 18.6765 20.4609 17.2073 20.4609 15.3953C20.4609 13.5829 18.9918 12.1137 17.1797 12.1137ZM16.9612 10.6224C17.6641 10.6224 18.2339 10.0525 18.2339 9.34963C18.2339 8.64672 17.6641 8.0769 16.9612 8.0769C16.2583 8.0769 15.6885 8.64672 15.6885 9.34963C15.6885 10.0525 16.2583 10.6224 16.9612 10.6224Z'
        fill='white'
      />
      <defs>
        <linearGradient
          id='paint0_linear_750_7432'
          x1='14'
          y1='-9.38773e-07'
          x2='24.2308'
          y2='22.0769'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#5748FF' />
          <stop offset='1' />
        </linearGradient>
      </defs>
    </svg>
  );
}
