import { tokenomicsMockData } from './dataset';
import './styles.css';
import { formatNumber } from 'utils/number';
import { ProgressInfo } from './components/ProgressInfo';
import { useTranslation } from 'react-i18next';

export function Tokenomics() {
  const mockData = tokenomicsMockData()
  const tokenomicsSum = mockData.reduce((acc, item) => acc + item.value, 0);
  const { t } = useTranslation();

  return (
    <div
      id='tokenomics'
      className='font-formatDJR-m mt-[1.5em] pl-[4em] pt-[3em] pr-[4em] md:px-4 md:py-8 pb-[4.313em] bg-white rounded-[40px]'
    >
      <p className='font-[600] font-par-m text-[3.875em] pb-[.3871em]'>
        3 000, 000, 000 SOCS
      </p>

      <div className='grid xl-1440:grid-cols-[1fr_2fr] grid-cols-1 pt-4 xl-1440:pt-0 '>
        <div className='hidden xl-1440:block'></div>
        <div className='flex flex-col gap-[3em]'>
          {mockData.map((item, i) => (
            <ProgressInfo
              key={i}
              label={item.label}
              value={(item.value / tokenomicsSum) * 100}
              color={item.color}
              formattedValue={formatNumber(item.value)}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
