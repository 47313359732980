import { useAccount } from 'wagmi';
import { useEffect } from 'react';
import { RegistrationFormType, Step } from '.';
import { useFormContext } from 'react-hook-form';
import { WalletButtons } from '../ConnectWalletDialog/components/WalletButtons';
import { useTranslation } from 'react-i18next';

type Props = {
  setIsLocked(val: boolean): void;
};

export const Wallets: React.FC<Props> = ({ setIsLocked }) => {
  const { isConnected } = useAccount();
  const form = useFormContext<RegistrationFormType>();
  const { t } = useTranslation()

  useEffect(() => {
    if (isConnected) form.setValue('step', Step.Email);
  }, [isConnected]);

  return (
    <>
      <h2 className='font-medium text-[1.75rem] leading-snug text-center text-black'>
        {t('wallets.select')}
      </h2>
      <div className='grid grid-cols-2 gap-2 sm:grid-cols-1'>
				<WalletButtons />
      </div>
    </>
  );
};
