interface MarqueeContentMap {
  iconSrc: string;
  text: string;
}

export const marqueeContentMap: MarqueeContentMap[] = [
  {
    iconSrc: require('assets/coins/cryptodaily.webp'),
    text: '',
  },
  {
    iconSrc: require('assets/coins/yahoo.webp'),
    text: '',
  },
  {
    iconSrc: require('assets/coins/uniswap.webp'),
    text: 'Uniswap',
  },
  {
    iconSrc: require('assets/coins/coinPedia.webp'),
    text: '',
  },
  {
    iconSrc: require('assets/coins/cryptonomist.webp'),
    text: '',
  },
];
