import { useAccount } from "wagmi";

const metaMask = require('../assets/wallets/metamask.png') as string;

export const walletIcons: Record<string, string> = {
  MetaMask: 'metamask',
  WalletConnect: 'walletConnect',
  'Coinbase Wallet': 'coinbase',
  'Crypto.com': 'cryptocom',
	'Trust Wallet': 'trustWallet'
};

export const useConnectedWalletIcon = (): string => {
  const {connector} = useAccount();

  return connector
    ? (require(`../assets/wallets/${
        walletIcons[connector.name]
      }.png`) as string)
    : metaMask;
};
