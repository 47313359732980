import { useAccount, useSignMessage } from 'wagmi';
import { DisclaimerDialog } from './components/DisclaymerDialog';
import { Hero } from './components/Hero';
import { useLayoutEffect, useState } from 'react';
import { authApi } from 'services/auth';
import { Carousel } from './components/Carousel/Carousel';
import { DesocRoadmap } from './components/Roadmap/DesocRoadmap';
import { Footer } from './components/Footer';
import { Tokenomics } from './components/Tokenomics';
import { FeaturesSocs } from './components/FeaturesSocs/FeaturesSocs';
import { Experience } from './components/Features/modules/Experience';
import { FAQ } from './components/FAQ';
import { SignMessageData, SignMessageVariables } from 'wagmi/query';
import { wagmiConfig } from 'configurations/wagmi';

export function LandingView() {
  const [isOpen, setIsOpen] = useState(false);

  const { address } = useAccount();
  const { signMessage } = useSignMessage({
    config: wagmiConfig,
    mutation: {
      onSuccess: (data: SignMessageData) => {
        if (!address) return;
        authApi.auth(address, data);
      },
    },
  });

  const handleOpenDisclaimer = (value: boolean) => {
    setIsOpen(value);
  };

  useLayoutEffect(() => {
    if (!address) return;

    const accessToken = localStorage.getItem('accessToken');

    if (!accessToken) {
      signMessage({ message: 'Sign to verify ownership', account: address });
      return;
    }

    authApi.refresh(address).catch(() => {
      signMessage({ message: 'Sign to verify ownership', account: address });
    });
  }, [address]);

  return (
    <div className='overflow-hidden'>
      <Hero />

      <div className='max-w-[1440px] mx-auto px-5 full-hd-bp:max-w-[1920px] full-hd-bp:text-[1.33rem] lg:text-[0.6rem]'>
        <Carousel />
      </div>

      <div className='max-w-[1440px] full-hd-bp:max-w-[1920px] mx-auto px-[2.5em] full-hd-bp:text-[1.33rem] lg:text-[0.6rem]'>
        <Experience />
      </div>

      <div className='max-w-[1440px] full-hd-bp:max-w-[1920px] mx-auto px-[2.5em] full-hd-bp:text-[1.33rem] lg:text-[0.6rem]'>
        <FeaturesSocs />

        <Tokenomics />

        <DesocRoadmap />

        <FAQ />
      </div>

      <div className='full-hd-bp:text-[1.33rem] lg:text-[0.6rem]'>
        <Footer handleOpenDisclaimer={handleOpenDisclaimer} />
      </div>

      <DisclaimerDialog
        isOpen={isOpen}
        handleOpenDisclaimer={handleOpenDisclaimer}
      />
    </div>
  );
}
