import { PaymentMethod } from 'constants/methods';
import { useLayoutEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useUserStore } from 'store/user';
import { Dialog } from 'ui-kit/Dialog';
import { ErrorResult } from './Error';
import { Referral } from './Referral';
import { SelectAsset } from './SelectAsset';
import { SendDeposit } from './SendDeposit';
import { SuccessResult } from './Success';
import { PaymentFormType, Step } from './scheme';

interface PaymentDialogProps {
  paymentInUSD: number;
  open: boolean;
  onOpenChange: (val: boolean) => void;
  paymentMethod: PaymentMethod;
}

export function PaymentDialog({
  paymentInUSD,
  open,
  onOpenChange,
  paymentMethod,
}: PaymentDialogProps) {
  const [canEnterRefCode] = useUserStore((state) => [state.canEnterRefCode]);

  const form = useForm<PaymentFormType>({
    defaultValues: {
      step: Step.SelectAsset,
      paymentMethod: paymentMethod,
      paymentStatus: 'WAITING_PAYMENT',
      payAmount: 0,
      payAddress: '',
      referral: '',
    },
  });

  const step = form.watch('step');

  const handleOpenChange = (val: boolean) => {
    onOpenChange(val);

    if (!val) {
      form.reset();
    }
  };

  useLayoutEffect(() => {
    if (!open) {
      form.setValue('step', canEnterRefCode ? Step.Referral : Step.SelectAsset);
    }
  }, [open, canEnterRefCode]);

  return (
    <Dialog
      open={open}
      onOpenChange={handleOpenChange}
      disableInteractOutside={step === Step.SendDeposit}
    >
      <FormProvider {...form}>
        <div className='font-formatDJR-b flex flex-col w-[517px] sm:text-center sm:w-[300px] gap-6 px-8 pt-[3.125rem] pb-8 max-h-[98vh] lg:overflow-y-scroll'>
          {step === Step.Referral && <Referral />}

          {step === Step.SelectAsset && (
            <SelectAsset
              paymentInUSD={paymentInUSD}
              preselectedMethod={paymentMethod}
            />
          )}

          {step === Step.SendDeposit && (
            <SendDeposit paymentInUSD={paymentInUSD} />
          )}

          {step === Step.Success && <SuccessResult />}

          {step === Step.Error && <ErrorResult />}
        </div>
      </FormProvider>
    </Dialog>
  );
}
