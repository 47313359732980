import { Root as AccordionRoot } from '@radix-ui/react-accordion';
import { FAQAccordion } from './FAQAccordion';
import { faqMockData } from './faqData';
import './styles.css';
import { useTranslation } from 'react-i18next';

export function FAQ() {
  const { t } = useTranslation()
  const faqData = faqMockData()

  return (
    <div
      className='relative flex flex-col items-center font-formatDJR-m pb-[7.06em] text-black'
      id='faq'
    >
      <div className='px-[4em] md:px-0 mb-[3em] flex justify-start items-center lg:flex-col lg:items-start'>
        <h2 className='font-par-m font-semibold text-[3.871em] leading-[1.2593em]'>
          {t('faq.title')}
        </h2>
      </div>
      <AccordionRoot
        type='single'
        collapsible
        className='flex flex-col w-full max-w-[698px] full-hd-bp:max-w-[800px] gap-[1em]'
      >
        {faqData.map(({ title, content }, i) => (
          <FAQAccordion key={`faq-${i}`} title={title} value={`faq-${i}`} breaklWords={i === 2}>
            {content}
          </FAQAccordion>
        ))}
      </AccordionRoot>
    </div>
  );
}
