import flagUK from '../assets/flags/Flag_of_UK.png'
import flagFR from '../assets/flags/Flag_of_France.png'
import flagNL from '../assets/flags/Flag_of_Netherlands.png'
import flagHI from '../assets/flags/Flag_of_India.png'
import flagPT from '../assets/flags/Flag_of_Portugal.png'
import flagIt from '../assets/flags/Flag_of_Italy.png'
import flagEs from '../assets/flags/Flag_of_Spain.png'
import flagTr from '../assets/flags/Flag_of_Turkish.png'
import flagPl from '../assets/flags/Flag_of_Poland.png'
import flagAr from '../assets/flags/Flag_of_Arabic.png'
import flagKo from '../assets/flags/Flag_of_South.png'
import flagJa from '../assets/flags/Flag_of_Japan.png'
import flagGe from '../assets/flags/Flag_of_Germany.png'
import flagCh from '../assets/flags/Flag_of_Chine.png'
import flagRu from '../assets/flags/Flag_of_Ru.png'
import flagCs from '../assets/flags/Flag_of_Czech_Republic.png'
import flagHu from '../assets/flags/Flag_of_Hungary_Flat.png'
import flagId from '../assets/flags/Flag_of_Indonesia.png'
import flagBg from '../assets/flags/Flag_of_Bulgaria.png'
import flagTh from '../assets/flags/Flag_of_Thai.png'
import flagRo from '../assets/flags/Flag_of_Romania.png'
import flagVi from '../assets/flags/Flag_of_Vietnam.png'
import flagSk from '../assets/flags/Flag_of_Slovakia.png'

export const countryFlags: { [key: string]: string } = {
  en: flagUK,
  hi: flagHI,
  nl: flagNL,
  fr: flagFR,
  pt: flagPT,
  it: flagIt,
  es: flagEs,
  pl: flagPl,
  tr: flagTr,
  ar: flagAr,
  ko: flagKo,
  ja: flagJa,
  de: flagGe,
  zh: flagCh,
  zh_tw: flagCh,
  ru: flagRu,
  cs: flagCs,
  hu: flagHu,
  id: flagId,
  bg: flagBg,
  th: flagTh,
  ro: flagRo,
  vi: flagVi,
  sk: flagSk
}

//ISO 639 language codes
// https://localizely.com/iso-639-1-list/

export const locales = {
  hi: 'Hindi',
  nl: 'Dutch',
  fr: 'French',
  pt: 'Portuguese',

  en: 'English',
  it: 'Italian',
  es: 'Spanish',
  pl: 'Polish',
  tr: 'Turkish',
  ar: 'Arabic',
  ko: "Korean",
  ja: 'Japanese',
  de: 'German',
  zh: 'Chinese (Simplified)',
  zh_tw: 'Chinese (Traditional)',
  ru: 'Russian',
  cs: 'Czech',
  hu: 'Hungarian',
  id: 'Indonesian',
  bg: 'Bulgarian',
  th: "Thai",
  ro: 'Romanian',
  vi: 'Vietnamese',
  sk: 'Slovakian'
}

export const rtlLocales = ['ar']
