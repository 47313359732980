export const countXTransition = ({
  index,
  count,
  cardWidth,
  parentSize,
}: {
  index: number;
  count: number;
  cardWidth: number | undefined;
  parentSize: number | undefined;
}) => {
  const INITIAL_GAP = 20;

  if (!cardWidth || !parentSize) {
    return undefined;
  }

  const initialAllCardsWidth = INITIAL_GAP * (count - 1) + cardWidth;
  const firstElementPosition = parentSize / 2 - initialAllCardsWidth / 2;

  const translayX =
    cardWidth * (index - 1) * -1 +
    firstElementPosition +
    INITIAL_GAP * (index - 1);

  return translayX;
};
